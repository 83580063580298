import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  IconButton,
  Skeleton,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiMinus, FiPlus } from "react-icons/fi"

const skeletonStartColor = "blackAlpha.100"
const skeletonEndColor = "blackAlpha.100"

interface Props {}

const LineItemPreview2Skeleton: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <Box
      h="297px"
      borderWidth={1}
      borderColor="blackAlpha.50"
      borderRadius="lg"
      boxShadow="lg"
    >
      <Flex
        height="185px"
        p={3}
        borderTopRadius="lg"
        bg="blackAlpha.50"
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress
          isIndeterminate
          color="primary.300"
          size="60px"
          thickness="4px"
          trackColor="transparent"
        />
      </Flex>
      <Flex h="110px" direction="column" justifyContent="space-between">
        <HStack flex={1} alignItems="center" px={3.5}>
          <Skeleton
            height="25px"
            startColor={skeletonStartColor}
            endColor={skeletonEndColor}
            flex={1}
          />
          <Skeleton
            height="25px"
            startColor={skeletonStartColor}
            endColor={skeletonEndColor}
            w="65px"
          />
        </HStack>
        <Box mx={3.5} height="2px" bg="blackAlpha.100" />
        <Flex
          px={3.5}
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <HStack spacing={0}>
            <IconButton
              size="sm"
              rounded="full"
              aria-label={t("components.cart.LineItemPreview.remove")}
              icon={<FiMinus />}
              disabled={true}
            />
            <Box textAlign="center" width="1.75rem">
              -
            </Box>
            <IconButton
              size="sm"
              rounded="full"
              aria-label={t("components.cart.LineItemPreview.add")}
              icon={<FiPlus />}
              disabled={true}
            />
          </HStack>
          <Skeleton
            height="20px"
            startColor={skeletonStartColor}
            endColor={skeletonEndColor}
            w="65px"
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default LineItemPreview2Skeleton
