import { EntityId } from "@jackfruit/common"
import { atom, atomFamily } from "recoil"

export const mobilePageNavigatorIsOpen = atom<boolean>({
  key: "mobilePageNavigatorIsOpen",
  default: false,
})

export const photoBookAllPagesNavigationIsOpen = atom<boolean>({
  key: "photoBookAllPagesNavigationIsOpen",
  default: false,
})

// Page Selection
export const currentSelectedPageIdFamily = atomFamily<EntityId, EntityId>({
  key: "currentSelectedPageId",
  default: "",
})

//Page Hover

export const isPhotoBookPageHoveredFamily = atomFamily<boolean, EntityId>({
  key: "isPhotoBookPageHovered",
  default: false,
})
