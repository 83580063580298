import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useLineItem } from "./useLineItem"
import { useProductPages } from "./useProductPages"
import { useTextRegions } from "./useTextRegions"

export const useAllTextRegionsForLineItem = (lineItemId: EntityId) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { productPages } = useProductPages(productPageIds)
  const allTextRegionIds = flatten(
    productPages.map(productPage => productPage.textRegionIds)
  )
  const { textRegions } = useTextRegions(allTextRegionIds)
  
  return { textRegions }
}
