import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { uploadsSelectors } from "~/redux/state/uploads"
import { RootState } from "~/redux/store"

export const useUpload = (id: EntityId) => {
  const upload = useSelector<RootState, UploadEntity | undefined>(state =>
    uploadsSelectors.selectById(state, id)
  )

  return { upload }
}
