import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useImageRegions } from "./useImageRegions"
import { useLineItem } from "./useLineItem"
import { useProductPages } from "./useProductPages"

export const useHasImagesForAllImageRegions = (lineItemId: EntityId) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { productPages } = useProductPages(productPageIds)
  const allImageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )
  const { imageRegions } = useImageRegions(allImageRegionIds)

  return imageRegions.every(imageRegion => imageRegion.uploadId !== undefined)
}
