import { EntityId } from "@jackfruit/common"
import { useLineItem } from "./useLineItem"
import { useProductImages } from "./useProductImages"

export const useLineItemFrame = (id: EntityId) => {
  const { lineItem } = useLineItem(id)
  const orientation = lineItem.orientation
  const { productImages } = useProductImages(lineItem.productId)
  const frameImages = productImages.filter(
    image => image.metaData?.frame !== undefined
  )

  let currentProductImage = null
  if (frameImages.length === 1) {
    currentProductImage = frameImages[0]
  } else {
    currentProductImage = frameImages.find(productImage => {
      if (orientation === "landscape") {
        return (
          productImage.metaData?.frame?.width! >
          productImage.metaData?.frame?.height!
        )
      } else {
        return (
          productImage.metaData?.frame?.width! <=
          productImage.metaData?.frame?.height!
        )
      }
    })
  }

  return {
    hasOverlay:
      currentProductImage?.metaData?.frame !== undefined &&
      currentProductImage?.metaData.frame?.layoutType === "inner",
    hasFrame:
      currentProductImage?.metaData?.frame !== undefined &&
      currentProductImage?.metaData.frame?.layoutType !== "inner",
    frame: currentProductImage,
    orientation,
    productImages: frameImages,
  }
}
