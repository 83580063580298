import { EntityId } from "@reduxjs/toolkit"
import { orderBy, uniqBy } from "lodash"
import { useSelector } from "react-redux"
import { productTemplateCategorySelectors } from "~/redux/state/productTemplates"
import { RootState } from "~/redux/store"
import { useCategories } from "./useCategories"
import { useProductTemplate } from "./useProductTemplate"

type SortOrder = "asc" | "desc"

export const useProductTemplateCategories = (
  id: EntityId,
  sortOrder: SortOrder = "desc"
) => {
  const { productTemplate } = useProductTemplate(id)

  let productTemplateCategories =
    useSelector((state: RootState) =>
      productTemplateCategorySelectors.selectByIds(
        state,
        productTemplate?.productTemplateCategories || []
      )
    ) || []

  productTemplateCategories = orderBy(
    productTemplateCategories,
    ["sortOrder"],
    [sortOrder]
  )

  const categoriesIds =
    productTemplateCategories.map(
      productTemplateCategory => productTemplateCategory.category
    ) || []

  const { categories } = useCategories(categoriesIds)

  return {
    productTemplate,
    productTemplateCategories,
    categories: uniqBy(categories, "id"),
  }
}
