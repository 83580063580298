import { EntityId } from "@jackfruit/common"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import {
  imageTransformations,
  imageTransformationsSelectors,
} from "~/redux/state/imageTransformations"
import { RootState } from "~/redux/store"

export const useImageTransform = (id: EntityId) => {
  const imageTransform = useSelector((state: RootState) =>
    imageTransformationsSelectors.selectById(state, id)
  )

  const data = useMemo(() => {
    const minZoom = imageTransform?.minZoom || 1
    const maxZoom = imageTransform?.maxZoom || 1
    const zoomStep = imageTransform?.zoomStep || 0.1

    return { imageTransform, minZoom, maxZoom, zoomStep }
  }, [imageTransform])

  return data
}

export const useImageTransformActions = () => {
  const dispatch = useDispatch()

  return {
    applyTransformations: useCallback(
      (id: EntityId, changes: Partial<ImageTransformationEntity>) => {
        dispatch(
          imageTransformations.actions.updateOne({
            id,
            changes,
          })
        )
      },
      [dispatch]
    ),
  }
}
