import { EntityId } from "@jackfruit/common"
import React, { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { useTextRegion } from "~/hooks/useTextRegion"
import {
  textRegionAlignFamily,
  textRegionColorFamily,
  textRegionFontFamily,
  textRegionKeyFamily,
  textRegionSizeFamily,
  textRegionTextFamily,
} from "../atoms"

interface Props {
  textRegionId: EntityId
}

const SyncReduxImageTransformToRecoil: React.FC<Props> = ({ textRegionId }) => {
  const { textRegion } = useTextRegion(textRegionId)
  const setAlign = useSetRecoilState(textRegionAlignFamily(textRegionId))
  const setColor = useSetRecoilState(textRegionColorFamily(textRegionId))
  const setFont = useSetRecoilState(textRegionFontFamily(textRegionId))
  const setKey = useSetRecoilState(textRegionKeyFamily(textRegionId))
  const setText = useSetRecoilState(textRegionTextFamily(textRegionId))
  const setSize = useSetRecoilState(textRegionSizeFamily(textRegionId))

  useEffect(() => {
    setAlign(textRegion?.align ?? "left")
    setColor(textRegion?.color ?? "")
    setFont(textRegion?.font ?? "")
    setKey(textRegion?.key ?? "")
    setText(textRegion?.text ?? "")
    setSize(textRegion?.size ?? 0.6)
  }, [setAlign, setColor, setFont, setKey, setSize, setText, textRegion])

  return null
}

export default SyncReduxImageTransformToRecoil
