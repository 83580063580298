import { Text, VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { debounce } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi"
import { scroller } from "react-scroll"
import { useSetRecoilState } from "recoil"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import EditorButton from "./EditorButton"
import { currentSelectedPageIdFamily } from "./pagination/atoms"

interface Props {
  lineItemId: EntityId
  selectedProductPageId: EntityId
}

const EditorAddPagesTool: React.FC<Props> = ({
  lineItemId,
  selectedProductPageId,
}) => {
  const { t } = useTranslation()
  const { lineItem, product } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { minImages, maxImages } = product
  const pageCount = productPageIds.length
  const currentPageIndex = productPageIds.indexOf(selectedProductPageId)
  const canAddMorePages = pageCount < maxImages
  const canInsertBefore = currentPageIndex > 0 && canAddMorePages
  const canInsertAfter =
    currentPageIndex > -1 && currentPageIndex < pageCount - 1 && canAddMorePages

  const { insertPageSpreadAfter, insertPageSpreadBefore } = useLineItemActions()
  const setCurrentSelectedPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const afterInsert = debounce((id: EntityId) => {
    scroller.scrollTo(`page-button-${id}`, {
      containerId: "editorPaginationContainer",
      duration: 800,
      offset: -200,
      delay: 0,
      smooth: "easeInOutQuart",
      horizontal: true,
    })
    setCurrentSelectedPageId(id)
  }, 100)

  const handleInsertPageSpreadBefore = () => {
    insertPageSpreadBefore(lineItemId, selectedProductPageId, afterInsert)
  }

  const handleInsertSpreadPageAfter = () => {
    insertPageSpreadAfter(lineItemId, selectedProductPageId, afterInsert)
  }

  return (
    <>
      <VStack spacing={2} width="full">
        <EditorButton
          id="insert-page-before"
          leftIcon={<FiChevronsLeft />}
          onClick={handleInsertPageSpreadBefore}
          isDisabled={!canInsertBefore}
        >
          {t("components.editors.common.EditorAddPagesTool.insertPagesBefore")}
        </EditorButton>
        <EditorButton
          id="insert-page-after"
          leftIcon={<FiChevronsRight />}
          onClick={handleInsertSpreadPageAfter}
          isDisabled={!canInsertAfter}
        >
          {t("components.editors.common.EditorAddPagesTool.insertPagesAfter")}
        </EditorButton>
      </VStack>
      <Text fontSize="sm" mt={4}>
        {t("components.editors.common.EditorAddPagesTool.minPageRequired", {
          minPages: minImages,
        })}
      </Text>
    </>
  )
}

export default EditorAddPagesTool
