import { Flex, FlexProps, Text } from "@chakra-ui/react"
import React from "react"

interface Props extends FlexProps {
  pageName: string
  children: React.ReactNode
}

const PhotoBookPageWrapper: React.FC<Props> = ({
  pageName,
  children,
  ...props
}) => {
  return (
    <Flex position="relative" direction="column" align="center" {...props}>
      {children}
      <Text mt={1}>{pageName}</Text>
    </Flex>
  )
}

export default PhotoBookPageWrapper
