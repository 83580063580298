import { EntityId } from "@jackfruit/common"
import React, { useMemo } from "react"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useImageTransform } from "~/hooks/useImageTransform"
import { useUpload } from "~/hooks/useUpload"
import ImageWithTransformations from "./ImageWithTransformations"

interface Props {
  imageRegionId: EntityId
  isRealTime?: Boolean
}

const ImageRegionPreview: React.FC<Props> = ({ imageRegionId, isRealTime }) => {
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const window = useMemo(() => imageRegion!.window, [imageRegion])
  const { imageTransform } = useImageTransform(image?.imageTransformId!)

  if (!image || !imageTransform) {
    return null
  }

  return (
    <svg
      x={window.x}
      y={window.y}
      width={window.width}
      height={window.height}
      viewBox={`${0} ${0} ${window.width} ${window.height}`}
    >
      <ImageWithTransformations imageId={image.id} isRealTime={isRealTime} />
    </svg>
  )
}

export default ImageRegionPreview
