import {
  Center,
  CircularProgress,
  CircularProgressLabel,
  Text,
  VStack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useUpload } from "~/hooks/useUpload"

interface Props {
  uploadId: EntityId
}

const EditorProcessing: React.FC<Props> = ({ uploadId }) => {
  const { t } = useTranslation()
  const { upload } = useUpload(uploadId)
  const { progress } = upload!

  const label =
    upload?.status === "compressing"
      ? t("components.editors.common.EditorProcessing.Processing")
      : t("components.editors.common.EditorProcessing.Uploading")

  return (
    <Center flex={1} justifyContent="center">
      {upload?.status !== "ready" && (
        <VStack>
          <CircularProgress
            isIndeterminate={upload?.status === "compressing"}
            size="60px"
            value={progress}
            color="primary.400"
          >
            {upload?.status !== "compressing" && (
              <CircularProgressLabel>
                {progress.toFixed(0)}%
              </CircularProgressLabel>
            )}
          </CircularProgress>
          <Text fontSize="sm">{label}</Text>
        </VStack>
      )}
    </Center>
  )
}

export default EditorProcessing
