import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { ImageEntity } from "~/interfaces/entities/Image"
import { imagesSelectors } from "~/redux/state/images"
import { RootState } from "~/redux/store"

export const useImages = (ids: EntityId[]) => {
  const images = useSelector<RootState, ImageEntity[]>(state =>
    imagesSelectors.selectByIds(state, ids)
  )

  return { images }
}
