import { Flex, Text } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useProductPrice } from "~/hooks/useProductPrice"
import { useSettings } from "~/hooks/useSettings"
import { formatCurrency } from "~/services/Utils"

interface Props {
  lineItemId: EntityId
}

const LineItemTotalPrice: React.FC<Props> = ({ lineItemId }) => {
  const { settings } = useSettings()

  // const experiment =
  //   useGoogleOptimize(settings.googleOptimizeExperimentIdFive, [
  //     LineItemProductPriceDisplayExperiment.hideAtAllTime,
  //     LineItemProductPriceDisplayExperiment.showPricePerProductOnly,
  //     LineItemProductPriceDisplayExperiment.showPricePerProductAndTotal,
  //   ]) ?? LineItemProductPriceDisplayExperiment.hideAtAllTime

  // * Hide GoogleOptimize for now due to odd behavior
  // const showTotalPrice =
  //   experiment ===
  //   LineItemProductPriceDisplayExperiment.showPricePerProductAndTotal
  const showTotalPrice = false

  const { lineItem, product } = useLineItem(lineItemId)
  const { productPrice } = useProductPrice(product.prices[0])

  if (!productPrice || !showTotalPrice) {
    return <Flex />
  }

  return (
    <Text fontSize="md" color="blackAlpha.400" fontWeight="semibold">
      {formatCurrency(
        productPrice.currency,
        productPrice.total * lineItem.quantity
      )}
    </Text>
  )
}

export default LineItemTotalPrice
