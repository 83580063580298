import { Box, Heading, useDisclosure, VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import LineItemProductTemplateSelector from "~/components/cart/LineItemProductTemplateSelector"
import { useAvailableProductTemplateVariants } from "~/hooks/useAvailableProductTemplateVariants"
import { useHasImagesForAllImageRegions } from "~/hooks/useHasImagesForAllImageRegions"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { scrollTo } from "~/services/Utils"
import { selectedRegion } from "../common/atoms"
import EditorActionButtons from "../common/EditorActionButtons"
import EditorChangeProductTemplateVariantButtons from "../common/EditorChangeProductTemplateVariantButtons"
import EditorSidebarContainer from "../common/EditorSidebarContainer"
import { currentSelectedPageIdFamily } from "../common/pagination/atoms"
import { EditorNoPhotoConfirmation } from "../print/PrintEditorSidebar"
import TemplateEditorSidebarControlsImage from "./TemplateEditorSidebarControlsImage"
import TemplateEditorSidebarControlsText from "./TemplateEditorSidebarControlsText"

interface Props {
  lineItemId: EntityId
  showTools: boolean
  onContinue: () => void
  onCancel: () => void
}

const TemplateEditorSidebar: React.FC<Props> = ({
  lineItemId,
  showTools,
  onCancel,
  onContinue,
}) => {
  const { type: selectedRegionType, id: selectedRegionId } =
    useRecoilValue(selectedRegion)
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { lineItem } = useLineItem(lineItemId)
  const setCurrentPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )
  const { variants } = useAvailableProductTemplateVariants(lineItemId)

  const hasImagesForAllImageRegions = useHasImagesForAllImageRegions(lineItemId)

  const hasMultiplePages = lineItem.productPageIds.length > 1
  const { applyEditorChanges } = useProcessActions()
  const { updateProduct, remove } = useLineItemActions()
  const { nextBlockType } = useNextActionableBlock("template")

  const handleProductChange = useCallback(
    (productId: EntityId) => {
      // navigate to the first page before changing product
      setCurrentPageId(lineItem.productPageIds[0])
      updateProduct(lineItemId, productId)
    },
    [lineItem.productPageIds, lineItemId, setCurrentPageId, updateProduct]
  )

  const handleCancel = useCallback(() => {
    // this editor is opened as a preview and we have
    // to remove the line item from the cart if the user
    // hit cancel at the first opening
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    }
    onCancel()
  }, [lineItem, onCancel, remove])

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
    onContinue()
    if (!lineItem.isConfirmed) {
      // Handle scroll only on a new item creation
      scrollTo(nextBlockType)
    }
    onClose()
  })

  const onContinueEnhanced = useCallback(() => {
    if (!hasImagesForAllImageRegions) {
      onOpen()
      return
    }
    handleSaveChanges()
  }, [handleSaveChanges, hasImagesForAllImageRegions, onOpen])

  const showImageControls = showTools && selectedRegionType === "image"
  const showTextControls =
    showTools && selectedRegionType === "text" && selectedRegionId !== 0

  const content = (
    <>
      <Box borderBottomWidth={1} mb={6}>
        <Heading as="h2" size="lg" mb={3}>
          {t("components.editors.template.TemplateEditorSidebar.Options")}
        </Heading>
      </Box>
      <Box>
        <VStack align="left" spacing={6} overflow="auto">
          <Box>
            <Heading as="h3" size="sm" mb={3}>
              {t("components.editors.template.TemplateEditorSidebar.Product")}
            </Heading>
            <VStack spacing={2} width="full">
              <LineItemProductTemplateSelector
                currentProductId={lineItem.productId}
                currentProductTemplateId={lineItem.productTemplateId!}
                onProductChange={handleProductChange}
                blockTemplateId={lineItem.blockTemplateId!}
              />
            </VStack>
          </Box>
          {variants.length > 1 && (
            <Box>
              <Heading as="h3" size="sm" mb={3}>
                {t(
                  "components.editors.template.TemplateEditorSidebar.DesignVariants"
                )}
              </Heading>
              <EditorChangeProductTemplateVariantButtons
                lineItemId={lineItemId}
              />
            </Box>
          )}
          {showImageControls && (
            <TemplateEditorSidebarControlsImage lineItemId={lineItemId} />
          )}
          {showTextControls && <TemplateEditorSidebarControlsText />}
        </VStack>
      </Box>

      <EditorNoPhotoConfirmation
        onClose={onClose}
        onConfirm={onClose}
        isOpen={isOpen}
        hasMultiplePages={hasMultiplePages}
      />
    </>
  )

  const actions = (
    <EditorActionButtons
      onContinue={onContinueEnhanced}
      onCancel={handleCancel}
      viewType="desktop"
    />
  )

  return <EditorSidebarContainer content={content} actions={actions} />
}

export default TemplateEditorSidebar
