import { EntityId } from "@jackfruit/common"
import React from "react"
import { ViewType } from "../Editor"
import GiftCardEditorContainer from "./GiftCardEditorContainer"
import GiftCardEditorContainerMobile from "./GiftCardEditorContainerMobile"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
  viewType: ViewType
}

const GiftCardEditor: React.FC<Props> = ({
  lineItemId,
  onComplete,
  viewType,
}) => {
  if (viewType === "mobile") {
    return (
      <GiftCardEditorContainerMobile
        lineItemId={lineItemId}
        onComplete={onComplete}
      />
    )
  }
  return (
    <GiftCardEditorContainer lineItemId={lineItemId} onComplete={onComplete} />
  )
}

export default GiftCardEditor
