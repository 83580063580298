import { useTheme } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilValue } from "recoil"
import { useImageRegion } from "~/hooks/useImageRegion"
import { isMultiImageRegionHovered, selectedRegion } from "../atoms"

interface Props {
  imageRegionId: EntityId
}

const EditorSvgUploadZoneSelector: React.FC<Props> = ({ imageRegionId }) => {
  const theme = useTheme()

  const { imageRegion } = useImageRegion(imageRegionId)
  const isHovered = useRecoilValue(isMultiImageRegionHovered(imageRegionId))
  const { type: selectedRegionType, id: selectedRegionId } =
    useRecoilValue(selectedRegion)

  const isSelected =
    selectedRegionType === "image" && imageRegionId === selectedRegionId

  // show only on image region with upload
  if (!Boolean(imageRegion?.uploadId)) {
    return null
  }

  const activeColor = theme.colors.primary[500]

  const { window } = imageRegion!
  const { x, y, width, height } = window
  const scalingRatio = Math.max(width / 1600, 0.3)
  const lineWidth = 10 * scalingRatio
  const dashValue = 35 * scalingRatio

  let strokeColor = isSelected ? activeColor : ""
  if (isHovered) {
    strokeColor = activeColor
  }

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      pointerEvents="none"
    >
      <rect
        x={lineWidth}
        y={lineWidth}
        width={width - lineWidth * 2}
        height={height - lineWidth * 2}
        stroke={strokeColor}
        strokeWidth={lineWidth}
        strokeDasharray={`${dashValue} ${dashValue}`}
        opacity={isHovered && !isSelected ? 0.5 : 1}
        fill="transparent"
      />
    </svg>
  )
}

export default EditorSvgUploadZoneSelector
