import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import LineItemPreviewImage from "./LineItemPreviewImage"

interface Props {
  lineItemId: EntityId
  isOpen: boolean
  onClose: () => void
}

const LineItemPreviewModal: React.FC<Props> = ({
  lineItemId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex height="50vh">
            <LineItemPreviewImage lineItemId={lineItemId} />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="primary" color="primary.text" onClick={onClose}>
            {t("components.cart.LineItemPreviewModal.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LineItemPreviewModal
