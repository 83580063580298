import { Box, useTheme } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useTextRegion } from "~/hooks/useTextRegion"
import {
  selectedRegion,
  textRegionAlignFamily,
  textRegionColorFamily,
  textRegionFontFamily,
  textRegionSizeFamily,
  textRegionTextFamily,
} from "../atoms"
import SvgText from "../text/SvgText"
import SvgTextInput from "../text/SvgTextInput"

interface Props {
  textRegionId: EntityId
  index: number
}

const EditorSvgTextRegionWrapper: React.FC<Props> = ({
  textRegionId,
  index,
}) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { textRegion } = useTextRegion(textRegionId)
  const { window, placeholder, defaultSize } = textRegion!

  const align = useRecoilValue(textRegionAlignFamily(textRegionId))
  const font = useRecoilValue(textRegionFontFamily(textRegionId))
  const size = useRecoilValue(textRegionSizeFamily(textRegionId))
  const color = useRecoilValue(textRegionColorFamily(textRegionId))
  const [text, setText] = useRecoilState(textRegionTextFamily(textRegionId))

  const { x, y, width, height } = window
  const isEmpty = text === ""

  const [_currentSelectedRegion, setSelectedRegion] =
    useRecoilState(selectedRegion)

  const [isActive, setIsActive] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus()
    }
  }, [isActive])

  const scalingRatio = Math.max(width / 1600, 0.3)
  const lineWidth = 10 * scalingRatio
  const dashValue = 35 * scalingRatio
  const padding = scalingRatio * 60

  if (isEmpty && !isActive) {
    return (
      <>
        <SvgText
          x={x + width / 2}
          y={y + height / 2}
          width={width + 2 * padding}
          height={height + 2 * padding}
          textAnchor="middle"
          verticalAnchor="middle"
          style={{
            fontSize: defaultSize,
            fill: theme.colors.primary[500],
          }}
        >
          {placeholder}
        </SvgText>
        <Box
          id={`text-region-button-${index}`}
          as="rect"
          x={x - padding}
          y={y - padding}
          width={width + 2 * padding}
          height={height + 2 * padding}
          fill="transparent"
          stroke={theme.colors.primary[500]}
          strokeWidth={lineWidth}
          rx="20"
          strokeDasharray={`${dashValue} ${dashValue}`}
          _hover={{ cursor: "pointer", fill: "rgba(255,255,255,0.2)" }}
          onClick={() => {
            setSelectedRegion({ type: "text", id: textRegionId })
            setIsActive(true)
          }}
        />
      </>
    )
  }

  return (
    <>
      <Box
        as="rect"
        x={x - padding}
        y={y - padding}
        width={width + 2 * padding}
        height={height + 2 * padding}
        fill="transparent"
        stroke={
          isFocused || isHovered ? theme.colors.primary[500] : "transparent"
        }
        strokeWidth={lineWidth}
        rx="20"
        strokeDasharray={`${dashValue} ${dashValue}`}
      />

      <SvgTextInput
        ref={inputRef}
        x={x}
        y={y}
        w={width}
        h={height}
        fontSize={size}
        fontFamily={font}
        color={color}
        value={text}
        textAlign={align}
        onChange={setText}
        onFocus={() => {
          setSelectedRegion({ type: "text", id: textRegionId })
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
          setIsActive(false)
        }}
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}
      />
    </>
  )
}

export default EditorSvgTextRegionWrapper
