import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { photoBookAllPagesNavigationIsOpen } from "../atoms"

export const usePhotoBookNavigatorDisclosure = () => {
  const [isOpen, setIsOpen] = useRecoilState(photoBookAllPagesNavigationIsOpen)
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return {
    isOpen,
    onOpen,
    onClose,
  }
}
