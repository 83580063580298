import { EntityId } from "@jackfruit/common"
import { useCallback, useMemo } from "react"
import { useLineItem } from "~/hooks/useLineItem"

export type PhotoBookPage = "cover" | "page" | "start" | "end"

export interface PagePair {
  type: PhotoBookPage
  productPageIds: EntityId[]
}

export const usePhotoBookPages = (
  lineItemId: EntityId,
  productPageId: EntityId
) => {
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)

  const pageLength = productPageIds.length
  const pageIndex = productPageIds.indexOf(productPageId)

  //page pair establisher for photo book editor
  const pagePair: PagePair = useMemo(() => {
    //if selected pageId is "cover"
    if (!productPageId || productPageId === "cover") {
      return {
        type: "cover",
        productPageIds: [],
      }
    }
    //if selected pageId is "end"
    if (productPageId === "end") {
      return {
        type: "end",
        productPageIds: [productPageIds[pageLength - 1]],
      }
    }

    if (pageIndex % 2 === 0) {
      const pairedPage = productPageIds[pageIndex - 1]
      if (pairedPage) {
        return {
          type: "page",
          productPageIds: [pairedPage, productPageId],
        }
      } else {
        return {
          type: "start",
          productPageIds: [productPageId],
        }
      }
    } else {
      const pairedPage = productPageIds[pageIndex + 1]
      if (pairedPage) {
        return {
          type: "page",
          productPageIds: [productPageId, pairedPage],
        }
      } else {
        return {
          type: "end",
          productPageIds: [productPageId],
        }
      }
    }
  }, [pageIndex, pageLength, productPageId, productPageIds])

  //get the page id on the right side of the book
  const getBondedPageId = useCallback(
    (type: PhotoBookPage) => {
      switch (type) {
        case "cover":
          return "cover"
        case "start":
          return productPageIds[0]
        case "end":
          return "end"
        default:
          if (pageIndex % 2 === 1) {
            return productPageIds[pageIndex + 1] ?? "end"
          } else {
            return productPageIds[pageIndex]
          }
      }
    },
    [pageIndex, productPageIds]
  )

  //if the selected page is on the left (odd number)
  //then return the right page id
  const getTrimmedProductPageId = useCallback(
    (id: EntityId) => {
      const index = productPageIds.indexOf(id)
      if (index % 2 === 1) {
        return productPageIds[index + 1] ?? "end"
      }
      return id
    },
    [productPageIds]
  )

  return {
    pagePair,
    getBondedPageId,
    getTrimmedProductPageId,
  }
}
