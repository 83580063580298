import { EntityId } from "@jackfruit/common"
import React from "react"
import { useImageRegion } from "~/hooks/useImageRegion"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { useElementBox } from "../hooks/useElementBox"
import EditorSvgUploadZoneInput from "./EditorSvgUploadZoneInput"
import EditorSvgUploadZoneNotEmpty from "./EditorSvgUploadZoneNotEmpty"

interface Props {
  lineItemId: EntityId
  imageRegionId: EntityId
}

const EditorSvgUploadZone: React.FC<Props> = ({
  lineItemId,
  imageRegionId,
}) => {
  const { imageRegion } = useImageRegion(imageRegionId)
  const { x, y } = imageRegion?.window!
  const { width, height } = imageRegion?.window!
  const { isPhotoBook } = usePhotoBook(lineItemId)
  const isImageUploaded = Boolean(imageRegion?.uploadId)

  const { ref, box } = useElementBox()

  const content = imageRegion?.uploadId ? (
    <EditorSvgUploadZoneNotEmpty imageRegionId={imageRegionId} box={box} />
  ) : (
    <EditorSvgUploadZoneInput
      lineItemId={lineItemId}
      imageRegionId={imageRegionId}
    />
  )

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`${0} ${0} ${width} ${height}`}
    >
      <rect
        ref={ref}
        x={0}
        y={0}
        width={width}
        height={height}
        fill={isPhotoBook && isImageUploaded ? "white" : "transparent"}
      />
      {content}
    </svg>
  )
}

export default EditorSvgUploadZone
