import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import { useWindowSize } from "react-use"

const EDITOR_BOTTOM_MARGIN = 280

interface Props {
  topbar: React.ReactElement
  editor: React.ReactElement
  toolbar?: React.ReactElement
  bottombar: React.ReactElement
  pageNavigator?: React.ReactElement
}

const EditorContainerMobile: React.FC<Props> = ({
  topbar,
  editor,
  toolbar,
  bottombar,
  pageNavigator,
}) => {
  const { height } = useWindowSize()
  const toolbarHeightCorrection = toolbar ? 0 : 95
  const editorContainerHeight =
    height - EDITOR_BOTTOM_MARGIN + toolbarHeightCorrection

  return (
    <Flex direction="column" bg="white">
      <Flex
        id="mobile-editor"
        height={height}
        direction="column"
        justifyContent="space-between"
        bg="blackAlpha.50"
      >
        <Flex direction="column" px={3} pt={5}>
          <Box mb={5}>{topbar}</Box>
          <Flex height={editorContainerHeight} justifyContent="center">
            {editor}
          </Flex>
        </Flex>
        <Flex
          direction="column"
          w="full"
          bg="white"
          boxShadow="0 4px 15px -3px rgba(0, 0, 0, 0.1),0 2px 6px -2px rgba(0, 0, 0, 0.05)"
          pb={5}
          px={3}
        >
          {toolbar}
          <Box>{bottombar}</Box>
        </Flex>
      </Flex>
      {pageNavigator}
    </Flex>
  )
}

export default EditorContainerMobile
