import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import CanvasWrapOverlay from "./CanvasWrapOverlay"

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const Canvas: React.FC<Props> = ({ lineItemId, children }) => {
  const { lineItem, product } = useLineItem(lineItemId)

  const { orientation } = lineItem
  const { pixelWidth, pixelHeight, metaData } = product
  const wrapPadding = metaData?.wrap

  const portraitWidth = Math.min(pixelWidth, pixelHeight)
  const portraitHeight = Math.max(pixelWidth, pixelHeight)

  const width = orientation === "portrait" ? portraitWidth : portraitHeight
  const height = orientation === "portrait" ? portraitHeight : portraitWidth

  return (
    <CanvasWrapOverlay
      wrapPadding={wrapPadding}
      safePadding={0}
      innerArea={{
        width,
        height,
      }}
    >
      {children}
    </CanvasWrapOverlay>
  )
}

export default Canvas
