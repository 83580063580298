import { EntityId } from "@jackfruit/common"
import React from "react"
import { useImageRegion } from "~/hooks/useImageRegion"
import EditorSvgPattern from "../EditorSvgPattern"
import EditorSvgUploadZone from "./EditorSvgUploadZone"

interface Props {
  lineItemId: EntityId
  imageRegionId: EntityId
}

const EditorSvgUploadZoneWrapper: React.FC<Props> = ({
  lineItemId,
  imageRegionId,
}) => {
  const { imageRegion } = useImageRegion(imageRegionId)
  const { window } = imageRegion!

  return (
    <>
      <EditorSvgPattern
        x={window.x}
        y={window.y}
        width={window.width}
        height={window.height}
      />

      <EditorSvgUploadZone
        lineItemId={lineItemId}
        imageRegionId={imageRegionId}
      />
    </>
  )
}

export default EditorSvgUploadZoneWrapper
