import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import { useMemo } from "react"
import { floatsAreEqual, getProductRatio } from "~/services/Utils"
import { useProductTemplateCategories } from "./useProductTemplateCategories"

export const useFilteredProductsForTemplate = (
  potentialProducts: PrintServiceProductEntityHydrated[],
  productTemplateId: EntityId
) => {
  const { categories, productTemplate } =
    useProductTemplateCategories(productTemplateId)

  const allVariants = productTemplate.variants

  const products = useMemo(
    () => {
      const productsWithCategoryMatch = categories.reduce<
        PrintServiceProductEntityHydrated[]
      >((acc, category) => {
        const productsForCategory = potentialProducts.filter(
          product => product.categoryName === category.name
        )

        return acc.concat(productsForCategory)
      }, [])

      const compatibleProducts = productsWithCategoryMatch.filter(product => {
        const { productRatio1, productRatio2 } = getProductRatio(product)

        const templatePageCount = allVariants[0].pages.length
        const hasMinimumCompatiblePages = product.minImages <= templatePageCount
        const hasMaximumCompatiblePages = product.maxImages >= templatePageCount

        const isPageCompatible =
          hasMinimumCompatiblePages && hasMaximumCompatiblePages

        const hasAtLeastOneVariant = allVariants.some(variant => {
          const firstPage = variant.pages[0]
          const pageRatio = firstPage.width / firstPage.height

          return (
            floatsAreEqual(productRatio1, pageRatio) ||
            floatsAreEqual(productRatio2, pageRatio)
          )
        })

        return hasAtLeastOneVariant && isPageCompatible
      })

      return compatibleProducts
    },
    // do not change this dep array
    // we dont need to recompute the list of products as the product list wont
    // change on the same page
    [allVariants, categories, potentialProducts]
  )

  return { products }
}
