import { Box, Portal } from "@chakra-ui/react"
import React from "react"
import { ViewBox } from "~/interfaces/editors/ViewBox"

interface Props {
  box: ViewBox
  children: React.ReactNode
}

const PortalOverlay: React.FC<Props> = ({ box, children }) => {
  return (
    <Portal>
      <Box
        zIndex={100}
        position="fixed"
        left={box.x}
        top={box.y}
        width={box.width}
        height={box.height}
      >
        {children}
      </Box>
    </Portal>
  )
}

export default PortalOverlay
