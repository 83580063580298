import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useTemplateTextColors } from "~/hooks/useTemplateTextColors"
import { selectedRegion, textRegionColorFamily } from "./atoms"

const EditorTextColorSelector: React.FC = () => {
  const { id } = useRecoilValue(selectedRegion)
  const { colors } = useTemplateTextColors()
  const [selectedColor, setColor] = useRecoilState(textRegionColorFamily(id))

  return (
    <Flex flexWrap="wrap" gap={1}>
      {colors.map(color => {
        const isSelected = color.hex === selectedColor
        return (
          <Box
            as={"a"}
            key={color.id}
            borderWidth={1}
            p={0.5}
            borderRadius="50%"
            _hover={{ cursor: "pointer", borderColor: "primary.500" }}
            borderColor={isSelected ? "primary.500" : "transparent"}
            onClick={() => setColor(color.hex)}
            className="text-color-select"
          >
            <Box
              width={7}
              height={7}
              borderRadius="50%"
              borderWidth={1}
              borderColor={
                /^#?[Ff]+$/.test(color.hex) ? "gray.200" : "transparent"
              }
              backgroundColor={color.hex}
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default EditorTextColorSelector
