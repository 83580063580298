import { Button, Flex, Icon, IconButton } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { IoIosArrowUp } from "react-icons/io"
import { usePhotoBookPagination } from "./hooks/usePhotoBookPagination"

interface Props {
  lineItemId: EntityId
  onOpen: () => void
}

const PhotoBookPageEditorPager: React.FC<Props> = ({ lineItemId, onOpen }) => {
  const { t } = useTranslation()
  const { hasPrevious, hasNext, handlePrevious, handleNext } =
    usePhotoBookPagination(lineItemId)

  return (
    <Flex mx={3} h={10}>
      <Flex flex={1} justify="center" align="center">
        <Button
          id="previous-page-button"
          isDisabled={!hasPrevious}
          variant="link"
          leftIcon={<Icon as={FiChevronLeft} boxSize={6} />}
          onClick={handlePrevious}
        >
          {t(
            "components.editors.common.pagination.PageEditorPager.PreviousButton"
          )}
        </Button>
      </Flex>
      <IconButton
        id="view-all-button"
        size="sm"
        bg="white"
        w={24}
        alignSelf="end"
        icon={<IoIosArrowUp />}
        aria-label={"View All"}
        borderBottomRadius={0}
        onClick={onOpen}
      />
      <Flex flex={1} justify="center" align="center">
        <Button
          id="next-page-button"
          isDisabled={!hasNext}
          variant="link"
          rightIcon={<Icon as={FiChevronRight} boxSize={6} />}
          onClick={handleNext}
        >
          {t("components.editors.common.pagination.PageEditorPager.NextButton")}
        </Button>
      </Flex>
    </Flex>
  )
}

export default PhotoBookPageEditorPager
