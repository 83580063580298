import { nanoid } from "@reduxjs/toolkit"
import React from "react"

interface Props {
  x: number
  y: number
  width: number
  height: number
  onClick?: React.MouseEventHandler<SVGRectElement>
}

const EditorSvgPattern: React.FC<Props> = ({
  x,
  y,
  width,
  height,
  onClick,
}) => {
  const id = nanoid()
  const patternSize = width / 16
  const cellSize = patternSize / 2
  const currentUrl = window.location.href

  return (
    <>
      <pattern
        id={id}
        x="0"
        y="0"
        width={patternSize}
        height={patternSize}
        patternUnits="userSpaceOnUse"
      >
        <rect x={0} y={0} width={cellSize} height={cellSize} fill="#EDF2F7" />
        <rect
          x={cellSize}
          y={0}
          width={cellSize}
          height={cellSize}
          fill="#ffffff"
        />
        <rect
          x={cellSize}
          y={cellSize}
          width={cellSize}
          height={cellSize}
          fill="#EDF2F7"
        />
        <rect
          x={0}
          y={cellSize}
          width={cellSize}
          height={cellSize}
          fill="#ffffff"
        />
      </pattern>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={`url(${currentUrl}#${id})`}
        onClick={onClick}
      ></rect>
    </>
  )
}

export default EditorSvgPattern
