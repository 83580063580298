import { Center } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import EditorProcessing from "../EditorProcessing"

interface Props {
  uploadId: EntityId
}

const EditorSvgUploadZoneProgress: React.FC<Props> = ({ uploadId }) => {
  return (
    <Center h="100%" w="100%" bgColor="transparent">
      <EditorProcessing uploadId={uploadId} />
    </Center>
  )
}

export default EditorSvgUploadZoneProgress
