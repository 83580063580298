import { useSelector } from "react-redux"
import { BlockTemplateEntityHydrated, EntityId } from "@jackfruit/common"
import { RootState } from "~/redux/store"
import { blockTemplatesSelectors } from "~/redux/state/blockTemplates"

export const useBlockTemplate = (
  blockTemplateId: EntityId
): { blockTemplate: BlockTemplateEntityHydrated } => {
  const blockTemplate = useSelector<RootState, BlockTemplateEntityHydrated>(
    state => blockTemplatesSelectors.selectById(state, blockTemplateId)!
  )

  return { blockTemplate }
}
