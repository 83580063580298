import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { ViewBox } from "~/interfaces/editors/viewbox"
import { getOrientationDimensions } from "~/services/Utils"
import { usePhotoBookPages } from "../pagination/hooks/usePhotoBookPages"
import Page from "./Page"

interface Props {
  lineItemId: EntityId
  productPageId: EntityId
}

const PhotoBook: React.FC<Props> = ({ lineItemId, productPageId }) => {
  const { photoBookFrame, photoBookCoverPreview } = usePhotoBook(lineItemId)
  const {
    product,
    lineItem: { orientation },
  } = useLineItem(lineItemId)

  const { pagePair } = usePhotoBookPages(lineItemId, productPageId)

  if (!photoBookFrame || !photoBookCoverPreview) {
    console.error("Please check server configuration for Photo Books")
    return null
  }

  const isCover = pagePair.type === "cover"
  const isStart = pagePair.type === "start"
  const isEnd = pagePair.type === "end"

  const { width: containerWidth, height: containerHeight } = isCover
    ? photoBookCoverPreview.metaData.photoBook!
    : photoBookFrame.metaData.editorFrame!

  const { width: productWidth, height: productHeight } =
    getOrientationDimensions(
      product.pixelWidth,
      product.pixelHeight,
      orientation ?? product.metaData?.orientationOverride ?? "landscape"
    )

  const innerViewBox: ViewBox = {
    x: 0,
    y: 0,
    width: productWidth,
    height: productHeight,
  }

  const imageUrl = isCover
    ? photoBookCoverPreview.thumbnail
    : photoBookFrame.thumbnail
  const frameClips = photoBookFrame.metaData.editorFrame?.windows!
  const firstWindow = frameClips[0]
  const lastWindow = frameClips[frameClips.length - 1]

  const frameComponent = (
    <image
      x={0}
      y={0}
      width={containerWidth}
      height={containerHeight}
      xlinkHref={imageUrl}
      pointerEvents="none"
    ></image>
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
    >
      {frameComponent}
      {!isCover &&
        frameClips.map((clip, index) => {
          const pageId =
            pagePair.productPageIds[index] ?? pagePair.productPageIds[0]

          if (isStart && clip === firstWindow) {
            //leave first window blank if it is a start page
            return null
          }
          if (isEnd && clip === lastWindow) {
            //leave last window blank if it is an end page
            return null
          }

          return (
            <svg
              key={index}
              x={clip.x}
              y={clip.y}
              width={clip.width}
              height={clip.height}
              viewBox={`0 0 ${innerViewBox.width} ${innerViewBox.height}`}
            >
              <Page lineItemId={lineItemId} productPageId={pageId!} />
            </svg>
          )
        })}
    </svg>
  )
}

export default PhotoBook
