import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import useGoogleOptimize from "@react-hook/google-optimize"
import React from "react"
import { useSettings } from "~/hooks/useSettings"
import LineItemPreviewProductSelector from "./LineItemPreviewProductSelector"

interface Props {
  currentProductId: EntityId
  onProductChange: (id: EntityId) => void
  products: PrintServiceProductEntityHydrated[]
  lineItemId: EntityId
}

const LineItemProductSelectorABTesting: React.FC<Props> = props => {
  const { settings } = useSettings()

  const ProductSelectorVariant =
    useGoogleOptimize(settings.googleOptimizeExperimentIdNine, [
      LineItemPreviewProductSelector,
      //LineItemProductSelectorDrawerButton, //prevent google optimize hook from being weird for now
      LineItemPreviewProductSelector,
    ]) ?? LineItemPreviewProductSelector

  return <ProductSelectorVariant {...props} />
}

export default LineItemProductSelectorABTesting
