import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useLineItemFrame } from "~/hooks/useLineItemFrame"
import { ViewBox } from "~/interfaces/editors/viewbox"
import { getOrientationDimensions } from "~/services/Utils"

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const Frame: React.FC<Props> = ({ lineItemId, children }) => {
  const {
    lineItem: { orientation },
    product: { pixelWidth, pixelHeight, metaData, categoryName },
  } = useLineItem(lineItemId)
  const { frame } = useLineItemFrame(lineItemId)!

  const imageUrl = frame?.thumbnail
  const isFrameUnderWindow = frame?.metaData.frame?.window.position === "front"

  const container = {
    width: frame?.metaData.frame?.width || pixelWidth,
    height: frame?.metaData.frame?.height || pixelHeight,
  }

  const frameClip: ViewBox = frame?.metaData?.frame?.window || {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }

  const { width, height } = getOrientationDimensions(
    pixelWidth,
    pixelHeight,
    orientation ?? metaData?.orientationOverride ?? "landscape"
  )

  const innerViewBox: ViewBox = {
    x: 0,
    y: 0,
    width,
    height,
  }

  const frameComponent = (
    <image
      x={0}
      y={0}
      width={container.width}
      height={container.height}
      xlinkHref={imageUrl}
      pointerEvents="none"
    ></image>
  )

  return (
    <svg x={0} y={0} viewBox={`0 0 ${container.width} ${container.height}`}>
      {isFrameUnderWindow && frameComponent}
      <svg
        x={frameClip.x}
        y={frameClip.y}
        width={frameClip.width}
        height={frameClip.height}
        viewBox={`0 0 ${innerViewBox.width} ${innerViewBox.height}`}
      >
        {children}
      </svg>
      {!isFrameUnderWindow && frameComponent}
      {categoryName === "magnet" && (
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          fill="none"
          stroke="#f5f5f5"
          strokeWidth={10}
          pointerEvents="none"
        />
      )}
    </svg>
  )
}

export default Frame
