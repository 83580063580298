import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { productPagesSelectors } from "~/redux/state/productPages"
import { RootState } from "~/redux/store"

export const useProductPage = (id: EntityId) => {
  const productPage = useSelector<RootState, ProductPageEntity | undefined>(
    state => productPagesSelectors.selectById(state, id)
  )

  return {
    productPage,
  }
}
