import { As, Box, Flex, Icon, Text } from "@chakra-ui/react"
import React from "react"

interface Props {
  label: string
  icon: As
  onClick?: () => void
}

const EditorToolbarDesktopButton: React.FC<Props> = ({
  label,
  icon,
  onClick,
}) => {
  return (
    <Flex
      p={2}
      px={3}
      w="full"
      as="button"
      direction="column"
      alignItems="center"
      onClick={onClick}
      transition="all 0.4s"
      color="gray.600"
      _active={{
        color: "primary.600",
        transform: "scale(0.85)",
      }}
    >
      <Icon as={icon} boxSize={8} />
      <Text
        fontWeight="semibold"
        color="inherit"
        fontSize="0.8em"
        userSelect="none"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </Flex>
  )
}

export default EditorToolbarDesktopButton
