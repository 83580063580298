import { EntityId } from "@jackfruit/common"
import React from "react"
import { Image } from "@chakra-ui/react"
import { useLineItem } from "~/hooks/useLineItem"
import { useLineItemFrame } from "~/hooks/useLineItemFrame"
import Canvas from "../../editors/common/layers/Canvas"
import Frame from "../../editors/common/layers/Frame"
import Overlay from "../../editors/common/layers/Overlay"
import StaticPage from "../../editors/common/layers/StaticPage"
import { useProductImages } from "~/hooks/useProductImages"
import FoldedCard from "~/components/editors/common/layers/FoldedCard"

interface Props {
  lineItemId: EntityId
  productPageId?: EntityId
}

const LineItemPreviewImage: React.FC<Props> = ({
  lineItemId,
  productPageId,
}) => {
  const { lineItem, product } = useLineItem(lineItemId)
  const { productImages } = useProductImages(lineItem.productId)
  const { hasFrame, hasOverlay } = useLineItemFrame(lineItemId)!
  const { metaData } = product
  const isGiftCertificate = product.categoryName === "gift certificate"
  const isACanvas = metaData?.wrap
  const isAFoldedCard = Boolean(product.metaData?.card?.folded)

  const pageToShow = productPageId ?? lineItem.productPageIds[0]
  const page = <StaticPage productPageId={pageToShow} />

  let component = null
  if (isAFoldedCard) {
    component = <FoldedCard lineItemId={lineItemId}>{page}</FoldedCard>
  } else if (isACanvas) {
    component = <Canvas lineItemId={lineItemId}>{page}</Canvas>
  } else if (hasFrame) {
    component = <Frame lineItemId={lineItemId}>{page}</Frame>
  } else if (hasOverlay) {
    component = <Overlay lineItemId={lineItemId}>{page}</Overlay>
  } else {
    component = page
  }

  const shouldCastShadow = !hasFrame && !hasOverlay

  return isGiftCertificate ? (
    <Image src={productImages[0].url} h="100%" mx="auto" />
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        width: "100%",
        height: "100%",
        filter: shouldCastShadow
          ? "drop-shadow(0px 3px 5px rgba(0,0,0,0.4))"
          : "initial",
      }}
    >
      {component}
    </svg>
  )
}

export default React.memo(LineItemPreviewImage)
