import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiChevronDown } from "react-icons/fi"
import { useRecoilState, useRecoilValue } from "recoil"
import { useTemplateTextFonts } from "~/hooks/useTemplateTextFonts"
import { selectedRegion, textRegionFontFamily } from "./atoms"

const EditorTextFontSelector: React.FC = () => {
  const { id } = useRecoilValue(selectedRegion)
  const { t } = useTranslation()
  const [font, setFont] = useRecoilState(textRegionFontFamily(id))
  const { fonts } = useTemplateTextFonts()

  const label =
    font ?? t(`components.editors.common.EditorTextFontSelector.SelectFont`)

  return (
    <Menu>
      <MenuButton
        w="full"
        textAlign="start"
        as={Button}
        backgroundColor="white"
        borderWidth={1}
        rightIcon={<FiChevronDown />}
        fontFamily={font}
      >
        {label}
      </MenuButton>
      <MenuList
        maxHeight={300}
        overflowY="scroll"
        overflowX="hidden"
        backgroundColor="white"
        zIndex={10}
      >
        {fonts.map(font => (
          <MenuItem
            key={font.id}
            p={1}
            mr={3}
            fontFamily={font.name}
            onClick={() => setFont(font.name)}
            className="font-select"
          >
            {font.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default EditorTextFontSelector
