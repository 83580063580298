import { Box, SimpleGrid } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  FiCamera,
  FiGrid,
  FiRotateCcw,
  FiZoomIn,
  FiZoomOut,
} from "react-icons/fi"
import { useRecoilValue } from "recoil"
import OrientIcon from "~/components/custom-icons/OrientIcon"
import { useSvgCropperToolBarHandlers } from "~/components/editors/common/hooks/useSvgCropperToolBarHandlers"
import { useCanSupportMultipleOrientations } from "~/hooks/useCanSupportMultipleOrientations"
import { useLineItem } from "~/hooks/useLineItem"
import EditorChangeImageWrapper from "./EditorChangeImageWrapper"
import EditorToolbarMobileButton from "./EditorToolbarMobileButton"
import { useMobilePageNavigatorDisclosure } from "./pagination/hooks/useMobilePageNavigatorDisclosure"
import { selectedRegion } from "./atoms"
import { usePhotoBookNavigatorDisclosure } from "./pagination/hooks/usePhotoBookNavigatorDisclosure"
import { usePhotoBook } from "~/hooks/usePhotoBook"

interface Props {
  lineItemId: EntityId
}

const EditorSvgCropperMobileToolBarLandscape: React.FC<Props> = ({
  lineItemId,
}) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { isPhotoBook } = usePhotoBook(lineItemId)
  const { id: imageRegionId } = useRecoilValue(selectedRegion)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const supportsMultipleOrientations =
    useCanSupportMultipleOrientations(lineItemId)

  const {
    handleRotateCcw,
    handleZoomIn,
    handleZoomOut,
    handleStartZoomingIn,
    handleStartZoomingOut,
    handleStopZoomingIn,
    handleStopZoomingOut,
    handleToggleOrientation,
  } = useSvgCropperToolBarHandlers(lineItemId, imageRegionId)

  const { onOpen: openPageNavigator } = useMobilePageNavigatorDisclosure()
  const { onOpen: openPhotoBookNavigator } = usePhotoBookNavigatorDisclosure()

  return (
    <SimpleGrid columns={3} gap={3}>
      <EditorChangeImageWrapper
        imageRegionId={imageRegionId}
        lineItemId={lineItemId}
      >
        <EditorToolbarMobileButton
          label={t(
            "components.editors.common.EditorSvgCropperMobileToolBarLandscape.Replace"
          )}
          icon={FiCamera}
        />
      </EditorChangeImageWrapper>
      {supportsMultipleOrientations ? (
        <EditorToolbarMobileButton
          label={t(
            "components.editors.common.EditorSvgCropperMobileToolBarLandscape.Orient"
          )}
          icon={OrientIcon}
          onClick={handleToggleOrientation}
        />
      ) : (
        <Box />
      )}
      {hasMultiplePages ? (
        <EditorToolbarMobileButton
          label={t(
            "components.editors.common.EditorSvgCropperMobileToolBarLandscape.AllPages"
          )}
          icon={FiGrid}
          onClick={isPhotoBook ? openPhotoBookNavigator : openPageNavigator}
        />
      ) : (
        <Box />
      )}
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBarLandscape.Rotate"
        )}
        icon={FiRotateCcw}
        onClick={handleRotateCcw}
      />
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBarLandscape.ZoomIn"
        )}
        icon={FiZoomIn}
        onClick={handleZoomIn}
        onTouchStart={handleStartZoomingIn}
        onTouchEnd={handleStopZoomingIn}
      />
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBarLandscape.ZoomIn"
        )}
        icon={FiZoomOut}
        onClick={handleZoomOut}
        onTouchStart={handleStartZoomingOut}
        onTouchEnd={handleStopZoomingOut}
      />
    </SimpleGrid>
  )
}

export default React.memo(EditorSvgCropperMobileToolBarLandscape)
