import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { ImageEntity } from "~/interfaces/entities/Image"
import { imagesSelectors } from "~/redux/state/images"
import { RootState } from "~/redux/store"

export const useImage = (id: EntityId) => {
  const image = useSelector<RootState, ImageEntity | undefined>(state =>
    imagesSelectors.selectById(state, id)
  )

  return { image }
}
