import { Box, Heading, VStack } from "@chakra-ui/react"

import React from "react"
import { useTranslation } from "react-i18next"
import EditorTextAlignmentSelector from "../common/EditorTextAlignmentSelector"
import EditorTextColorSelector from "../common/EditorTextColorSelector"
import EditorTextFontSelector from "../common/EditorTextFontSelector"
import EditorTextZoomSlider from "../common/EditorTextZoomSlider"

interface Props {}

const TemplateEditorSidebarControlsText: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <VStack spacing={6} alignItems="stretch" w="full">
      <Box>
        <Heading as="h3" size="sm" mb={3}>
          {t("components.editors.template.TemplateEditorSidebar.FontStyle")}
        </Heading>
        <EditorTextFontSelector />
      </Box>
      <Box>
        <Heading as="h3" size="sm" mb={3}>
          {t("components.editors.template.TemplateEditorSidebar.TextSize")}
        </Heading>
        <EditorTextZoomSlider />
      </Box>
      <Box>
        <Heading as="h3" size="sm" mb={3}>
          {t("components.editors.template.TemplateEditorSidebar.TextAlignment")}
        </Heading>
        <EditorTextAlignmentSelector />
      </Box>
      <Box>
        <Heading as="h3" size="sm" mb={3}>
          {t("components.editors.template.TemplateEditorSidebar.TextColor")}
        </Heading>
        <EditorTextColorSelector />
      </Box>
    </VStack>
  )
}

export default TemplateEditorSidebarControlsText
