import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { availableProductsForBlockSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"

export const useCurrentAvailableProductsForBlock = (id: EntityId) => {
  const products = useSelector((state: RootState) =>
    availableProductsForBlockSelector(state, id)
  )

  return { products }
}
