import { Box, useTheme } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { useImageRegion } from "~/hooks/useImageRegion"
import { regionActivation } from "../atoms"

interface Props {
  imageRegionId: EntityId
  hasMultipleRegions: boolean
}

const EditorSvgUploadHighlight: React.FC<Props> = ({
  imageRegionId,
  hasMultipleRegions,
}) => {
  const { t } = useTranslation()

  const isActive = useRecoilValue(
    regionActivation({ type: "image", id: imageRegionId })
  )
  const theme = useTheme()
  const { imageRegion } = useImageRegion(imageRegionId)

  if (imageRegion?.uploadId) {
    return null
  }

  const { window } = imageRegion!
  const { x, y, width, height } = window
  const scalingRatio = Math.max(width / 1600, 0.3)
  const lineWidth = 10 * scalingRatio
  const dashValue = 35 * scalingRatio

  //TODO: fit all templates/frames we have in the future
  //TODO: Better to use preserveAspectRatio - suggested from Dave and Isaac
  //For Pet Bowls and Pet Collars templates ATM
  const ratio = width / height
  const isWideImageRegion = height < 2600 && ratio > 2.2

  const buttonColor = theme.colors.primary[500]
  const buttonWidth = 104
  const buttonHeight = 28

  const iconWidth = 24
  const iconHeight = 22

  const iconScale = isWideImageRegion ? height / 60 : width / 150
  const buttonScale = iconScale / 2

  const activeRegionFill = isActive ? "whiteAlpha.600" : "whiteAlpha.500"
  let fill = hasMultipleRegions ? "transparent" : activeRegionFill

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      pointerEvents="none"
    >
      <Box
        as="rect"
        x={lineWidth}
        y={lineWidth}
        width={width - lineWidth * 2}
        height={height - lineWidth * 2}
        fill={fill}
        stroke={hasMultipleRegions && !isActive ? "" : buttonColor}
        strokeWidth={lineWidth}
        rx="20"
        strokeDasharray={`${dashValue} ${dashValue}`}
      />

      <g
        fill="none"
        width={iconWidth}
        height={iconHeight}
        strokeWidth={2}
        stroke={buttonColor}
        style={{
          transform: `translateX(${
            width / 2 - (iconWidth * iconScale) / 2
          }px) translateY(${
            isWideImageRegion
              ? height / 2 - iconHeight * iconScale
              : height / 2 - (iconHeight * iconScale) / 2
          }px) scale(${iconScale})`,
        }}
      >
        <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
        <circle cx="12" cy="13" r="4"></circle>
      </g>

      <g
        style={{
          transform: `translateX(${width / 2}px) translateY(${
            isWideImageRegion
              ? height / 2 + (iconHeight * iconScale) / 2
              : height / 2 + iconHeight * 1.2 * iconScale
          }px) scale(${buttonScale})`,
        }}
      >
        <rect
          x={-buttonWidth / 2}
          y={-buttonHeight / 2}
          width={buttonWidth}
          height={buttonHeight}
          fill={buttonColor}
          rx={4}
        />
        <text
          x={0}
          y={0}
          fontSize={12}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontWeight="bold"
          fill="white"
        >
          {t(
            "components.editors.common.upload-zone.EditorSvgUploadHighlight.UploadPhoto"
          )}
        </text>
      </g>
    </svg>
  )
}

export default EditorSvgUploadHighlight
