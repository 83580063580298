import { EntityId } from "@jackfruit/common"
import { chunk, tail } from "lodash"
import { useLineItem } from "~/hooks/useLineItem"

export const usePhotoBookSpreads = (lineItemId: EntityId) => {
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)

  // split the page list in spreads, special cases for first and last page that
  // share the spread with the the cover
  // [[0], [1,2], [3,4], ..., [n,n+1], [100]]
  const firstPageId = productPageIds[0]
  const middlePageIds = tail(productPageIds)
  const paginated = [[firstPageId], ...chunk(middlePageIds, 2)]

  return paginated
}
