import { useSelector } from "react-redux"
import { templateTextColorsSelector } from "~/redux/state/template-text-colors"

export const useTemplateTextColors = () => {
  const colors = useSelector(templateTextColorsSelector.selectAll)

  return {
    colors,
  }
}
