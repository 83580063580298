import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"

interface Props extends BoxProps {}

const EditorButtonContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box backgroundColor="gray.100" borderRadius="md">
      {children}
    </Box>
  )
}

export default EditorButtonContainer
