import { Select, SelectProps } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useBlockTemplate } from "~/hooks/useBlockTemplate"
import { useCurrentAvailableProductsForBlock } from "~/hooks/useCurrentAvailableProductsForBlock"
import { useFilteredProductsForTemplate } from "~/hooks/useFilteredProductsForTemplate"

export interface Props extends SelectProps {
  isDisabled?: boolean
  currentProductId: EntityId
  blockTemplateId: EntityId
  currentProductTemplateId: EntityId
  onProductChange: (id: EntityId) => void
}

const LineItemProductTemplateSelector: React.FC<Props> = ({
  currentProductId,
  currentProductTemplateId,
  blockTemplateId,
  onProductChange,
  ...props
}) => {
  const { blockTemplate } = useBlockTemplate(blockTemplateId)
  const { products: potentialProducts } = useCurrentAvailableProductsForBlock(
    blockTemplate.blockId
  )

  const { products } = useFilteredProductsForTemplate(
    potentialProducts,
    currentProductTemplateId
  )

  const handleProductChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onProductChange(event.target.value)
    },
    [onProductChange]
  )

  return (
    <Select
      {...props}
      fontWeight="semibold"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      color="gray.500"
      backgroundColor="white"
      colorScheme="gray"
      _hover={{
        cursor: "pointer",
      }}
      value={currentProductId}
      onChange={handleProductChange}
    >
      {products.map(product => {
        return (
          <option
            key={product.id}
            value={product.id}
            data-remote-id={product.remoteId}
            data-local-id={product.id}
          >
            {product.shortDescription}
          </option>
        )
      })}
    </Select>
  )
}

export default LineItemProductTemplateSelector
