import { Box, Flex, Grid, GridItem } from "@chakra-ui/react"
import React from "react"
import { useWindowSize } from "react-use"

interface Props {
  topbar: React.ReactElement
  editor: React.ReactElement
  toolbar: React.ReactElement | null
  bottombar: React.ReactElement
  pageNavigator?: React.ReactElement
}

const EditorContainerMobileLandscape: React.FC<Props> = ({
  topbar,
  editor,
  toolbar,
  bottombar,
  pageNavigator,
}) => {
  const { height } = useWindowSize()
  return (
    <Flex direction="column" bg="white">
      <Grid
        id="mobile-editor-landscape"
        height={height}
        templateColumns="repeat(5, 1fr)"
        gap={1}
        bg="blackAlpha.50"
      >
        <GridItem colSpan={2} bg="white" boxShadow="lg">
          <Flex
            direction="column"
            justify="space-between"
            px={3}
            py={5}
            height="full"
          >
            <Box>{topbar}</Box>
            {toolbar && <Box py={5}>{toolbar}</Box>}
            <Box>{bottombar}</Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={3} height={height}>
          <Flex height="full">{editor}</Flex>
        </GridItem>
      </Grid>
      {pageNavigator}
    </Flex>
  )
}

export default EditorContainerMobileLandscape
