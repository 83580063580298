import { Box, Flex } from "@chakra-ui/react"
import React from "react"

interface Props {
  content: React.ReactElement
  actions: React.ReactElement
}

const EditorSidebarContainer: React.FC<Props> = ({ content, actions }) => {
  return (
    <Flex direction="column" justifyContent="space-between" height="100%">
      <Box>{content}</Box>
      <Box borderTopWidth={1} pt={3}>
        {actions}
      </Box>
    </Flex>
  )
}

export default EditorSidebarContainer
