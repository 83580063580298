import { Box, SimpleGrid } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "./atoms"
import { useMobilePageNavigatorDisclosure } from "./hooks/useMobilePageNavigatorDisclosure"
import NavigationDrawer from "./NavigationDrawer"
import PageNavigationButton from "./PageNavigationButton"

interface Props {
  lineItemId: EntityId
}

const PageEditorMobilePageNavigation: React.FC<Props> = ({ lineItemId }) => {
  const { isOpen, onClose } = useMobilePageNavigatorDisclosure()
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const onClick = (id: EntityId) => {
    setCurrentSelectedPageId(id)
  }

  return (
    <NavigationDrawer isOpen={isOpen} onClose={onClose}>
      <SimpleGrid
        p={3}
        columns={{
          base: 2,
          sm: 3,
          md: 5,
          lg: 6,
          xl: 8,
        }}
        gap={3}
      >
        {productPageIds.map((pageId, index) => {
          return (
            <Box key={pageId}>
              <PageNavigationButton
                lineItemId={lineItemId}
                pageId={pageId}
                index={index}
                isFoldedCard={false}
                onClick={onClick}
                isActive={pageId === currentSelectedPageId}
              />
            </Box>
          )
        })}
      </SimpleGrid>
    </NavigationDrawer>
  )
}

export default PageEditorMobilePageNavigation
