import { EntityId } from "@jackfruit/common"
import React, { useEffect, useMemo } from "react"
import { useSetRecoilState } from "recoil"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { useProductPage } from "~/hooks/useProductPage"
import { sanitizeTemplate } from "~/services/SvgUtils"
import { selectedRegion } from "../atoms"
import EditorSvgTextRegionWrapper from "../text-region/EditorSvgTextRegionWrapper"
import EditorSvgUploadHighlight from "../upload-zone/EditorSvgUploadHighlight"
import EditorSvgUploadZoneSelector from "../upload-zone/EditorSvgUploadZoneSelector"
import EditorSvgUploadZoneWrapper from "../upload-zone/EditorSvgUploadZoneWrapper"

interface RegionAndType {
  id: EntityId
  type: "image" | "text"
}

interface Props {
  lineItemId: EntityId
  productPageId: EntityId
}

const Page: React.FC<Props> = ({ lineItemId, productPageId }) => {
  const { productPage } = useProductPage(productPageId)
  const { isPhotoBook } = usePhotoBook(lineItemId)

  const imageRegionIds = useMemo(() => {
    return productPage?.imageRegionIds ?? []
  }, [productPage?.imageRegionIds])

  const textRegionIds = useMemo(() => {
    return productPage?.textRegionIds ?? []
  }, [productPage?.textRegionIds])

  const sanitizedSvg = useMemo(() => {
    return productPage?.svg ? sanitizeTemplate(productPage?.svg) : ""
  }, [productPage])

  const viewBoxWidth = productPage?.width ?? 0
  const viewBoxHeight = productPage?.height ?? 0

  const setSelectedRegion = useSetRecoilState(selectedRegion)

  useEffect(() => {
    const allRegionAndTypes = imageRegionIds
      .map(id => ({ id, type: "image" }))
      .concat(
        textRegionIds.map(id => ({ id, type: "text" }))
      ) as RegionAndType[]

    if (allRegionAndTypes.length > 0) {
      // select the first image region when opening the editor
      // or switching to a new page on a multiple page product
      setSelectedRegion(allRegionAndTypes[0])
    }
    return () => {
      setSelectedRegion({ type: "image", id: 0 })
    }
  }, [imageRegionIds, textRegionIds, productPageId, setSelectedRegion])

  const hasMultipleRegions =
    imageRegionIds.length > 1 || textRegionIds.length > 0 || isPhotoBook
  const hasMultipleImageRegions = imageRegionIds.length > 1 || isPhotoBook

  return (
    <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
      {imageRegionIds.map(imageRegionId => {
        return (
          <EditorSvgUploadZoneWrapper
            key={imageRegionId}
            lineItemId={lineItemId}
            imageRegionId={imageRegionId}
          />
        )
      })}
      <g
        width="100%"
        height="100%"
        style={{ pointerEvents: "none" }}
        dangerouslySetInnerHTML={{
          __html: sanitizedSvg,
        }}
      ></g>
      {imageRegionIds.map(imageRegionId => {
        return (
          <EditorSvgUploadHighlight
            key={imageRegionId}
            imageRegionId={imageRegionId}
            hasMultipleRegions={hasMultipleRegions}
          />
        )
      })}
      {hasMultipleImageRegions &&
        imageRegionIds.map(imageRegionId => {
          return (
            <EditorSvgUploadZoneSelector
              key={imageRegionId}
              imageRegionId={imageRegionId}
            />
          )
        })}
      {textRegionIds.map((textRegionId: EntityId, index: number) => {
        return (
          <EditorSvgTextRegionWrapper
            index={index}
            key={textRegionId}
            textRegionId={textRegionId}
          />
        )
      })}
    </svg>
  )
}

export default Page
