import {
  Button,
  Flex,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SelectProps,
  Text,
} from "@chakra-ui/react"
import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import React, { useCallback } from "react"
import { FaChevronDown } from "react-icons/fa"
import { useCurrentAvailableProductsForPage } from "~/hooks/useCurrentAvailableProductsForPage"
import { useProductPrices } from "~/hooks/useProductPrices"
import { useApplicationState } from "~/hooks/useApplicationState"
import { formatCurrency } from "~/services/Utils"

interface ProductSelectOptionProps extends FlexProps {
  product?: PrintServiceProductEntityHydrated
}

const ProductSelectOption = ({
  product,
  ...props
}: ProductSelectOptionProps) => {
  const { defaultCurrency } = useApplicationState()
  const { productPrices } = useProductPrices(product?.prices ?? [])
  const productPrice = productPrices.find(
    price => price.currency === defaultCurrency
  )
  const showPrice = productPrice && product?.metaData?.web?.displayPricing

  return (
    <Flex
      justifyContent="space-between"
      fontWeight="semibold"
      color="gray.500"
      flex={1}
      {...props}
    >
      <Flex flex={1} overflow="hidden">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {product?.shortDescription}
        </Text>
      </Flex>
      {showPrice && (
        <Flex ml={3}>
          <Text>
            {formatCurrency(productPrice.currency, productPrice.total)}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export interface Props extends SelectProps {
  currentProductId: EntityId
  onProductChange: (id: EntityId) => void
}

const LineItemProductSelector: React.FC<Props> = ({
  currentProductId,
  onProductChange,
}) => {
  const { products } = useCurrentAvailableProductsForPage()
  const currentProduct = products.find(
    product => product.id === Number(currentProductId)
  )!

  const handleProductChange = useCallback(
    (id: EntityId) => {
      onProductChange(id)
    },
    [onProductChange]
  )

  return (
    <Menu matchWidth={true}>
      <MenuButton
        as={Button}
        rightIcon={<FaChevronDown />}
        w="full"
        color="gray.500"
        backgroundColor="white"
        borderWidth={1}
        data-remote-id={currentProduct?.remoteId}
        data-local-id={currentProduct?.id}
      >
        <ProductSelectOption product={currentProduct} />
      </MenuButton>
      <MenuList
        maxHeight="50vh"
        overflowY="scroll"
        zIndex={100}
        transform="none !important"
      >
        {products.map(product => {
          const isSelected = product.id === currentProductId
          return (
            <MenuItem
              className={`cart-product-option-${product.remoteId}`}
              key={product.id}
              data-remote-id={product.remoteId}
              data-local-id={product.id}
              bg={isSelected ? "gray.200" : "transparent"}
              onClick={() => handleProductChange(product.id)}
            >
              <ProductSelectOption product={product} />
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default LineItemProductSelector
