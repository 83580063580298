import { Box, Divider, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { FiCamera, FiRotateCcw, FiZoomIn, FiZoomOut } from "react-icons/fi"
import OrientIcon from "~/components/custom-icons/OrientIcon"
import { useCanSupportMultipleOrientations } from "~/hooks/useCanSupportMultipleOrientations"
import { useSvgCropperToolBarHandlers } from "~/components/editors/common/hooks/useSvgCropperToolBarHandlers"
import EditorChangeImageWrapper from "./EditorChangeImageWrapper"
import EditorToolbarDesktopButton from "./EditorToolbarDesktopButton"
import { useRecoilValue } from "recoil"
import { currentSelectedPageIdFamily } from "./pagination/atoms"
import { useProductPage } from "~/hooks/useProductPage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useTranslation } from "react-i18next"
import { useImage } from "~/hooks/useImage"
import { useUpload } from "~/hooks/useUpload"
import EditorImageZoomSliderTopbar from "./EditorImageZoomSliderTopbar"

interface Props {
  lineItemId: EntityId
}

const EditorSvgCropperDesktopToolBarImage: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()
  const currentPageId = useRecoilValue(currentSelectedPageIdFamily(lineItemId))
  const { productPage } = useProductPage(currentPageId)
  const { imageRegion } = useImageRegion(productPage!.imageRegionIds[0])
  const imageRegionId = imageRegion?.id!

  const supportsMultipleOrientations =
    useCanSupportMultipleOrientations(lineItemId)

  const {
    handleRotateCcw,
    handleZoomIn,
    handleZoomOut,
    handleToggleOrientation,
  } = useSvgCropperToolBarHandlers(lineItemId, imageRegionId)

  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)

  const imageTransformId = image?.imageTransformId!

  return (
    <Flex direction="row">
      <EditorChangeImageWrapper
        imageRegionId={imageRegionId}
        lineItemId={lineItemId}
      >
        <EditorToolbarDesktopButton
          label={t(
            "components.editors.common.EditorSvgCropperDesktopToolBarImage.Replace"
          )}
          icon={FiCamera}
        />
      </EditorChangeImageWrapper>
      {supportsMultipleOrientations && (
        <EditorToolbarDesktopButton
          label={t(
            "components.editors.common.EditorSvgCropperDesktopToolBarImage.Orient"
          )}
          icon={OrientIcon}
          onClick={handleToggleOrientation}
        />
      )}
      <EditorToolbarDesktopButton
        label={t(
          "components.editors.common.EditorSvgCropperDesktopToolBarImage.Rotate"
        )}
        icon={FiRotateCcw}
        onClick={handleRotateCcw}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <EditorToolbarDesktopButton
        label={t(
          "components.editors.common.EditorSvgCropperDesktopToolBarImage.ZoomOut"
        )}
        icon={FiZoomOut}
        onClick={handleZoomOut}
      />
      <EditorImageZoomSliderTopbar
        imageTransformId={imageTransformId}
        disabled={false}
      />
      <EditorToolbarDesktopButton
        label={t(
          "components.editors.common.EditorSvgCropperDesktopToolBarImage.ZoomIn"
        )}
        icon={FiZoomIn}
        onClick={handleZoomIn}
      />
    </Flex>
  )
}

export default React.memo(EditorSvgCropperDesktopToolBarImage)
