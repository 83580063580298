import { Textarea } from "@chakra-ui/react"
import React from "react"
import SvgText from "./SvgText"

import { TextAnchor } from "./types"

interface Props {
  x: number
  y: number
  w: number
  h: number
  value: string
  fontSize: number
  textAlign: "left" | "center" | "right"
  fontFamily: string
  color: string
  onFocus: () => void
  onBlur: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onChange: (value: string) => void
}

const textAnchors = {
  left: "start",
  center: "middle",
  right: "end",
}

const SvgTextInput = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      h,
      w,
      x: defaultX,
      y,
      value,
      fontSize,
      textAlign,
      fontFamily,
      color,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      onChange,
    },
    ref
  ) => {
    const lineHeight = fontSize * 1.3
    const magicNumberForForeignObjectCorrection = 3.2
    const textAnchor = textAnchors[textAlign] as TextAnchor

    let x = textAlign === "center" ? defaultX + w / 2 : defaultX
    if (textAlign === "right") {
      x = defaultX + w
    }

    let textAreaX = textAlign === "center" ? defaultX : x
    if (textAlign === "right") {
      textAreaX = defaultX
    }

    const handleTextChange = (newValue: string) => {
      onChange(newValue.replace(/ +(?= )/g, ""))
    }

    return (
      <>
        <SvgText
          x={x}
          y={y}
          width={w}
          height={h}
          textAnchor={textAnchor}
          verticalAnchor="start"
          lineHeight={`${lineHeight}px`}
          style={{
            fontFamily,
            lineHeight,
            fontSize,
            fill: color,
          }}
        >
          {value}
        </SvgText>

        <foreignObject
          x={textAreaX}
          y={y - fontSize / magicNumberForForeignObjectCorrection}
          width={w}
          height={h}
        >
          <Textarea
            ref={ref}
            id="text-region-input"
            w={w}
            h={h}
            p={0}
            m={0}
            _focus={{
              outline: "none",
            }}
            style={{ caretColor: "black" }}
            textAlign={textAlign}
            borderWidth={0}
            overflow="hidden"
            fontFamily={fontFamily}
            fontSize={`${fontSize}px`}
            lineHeight={`${lineHeight}px`}
            color="transparent"
            onChange={e => handleTextChange(e.target.value)}
            value={value}
            className="svg-text-input"
            resize="none"
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          ></Textarea>
        </foreignObject>
      </>
    )
  }
)

export default SvgTextInput
