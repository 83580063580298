import { SelectProps } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useBlockTemplate } from "~/hooks/useBlockTemplate"
import { useCurrentAvailableProductsForBlock } from "~/hooks/useCurrentAvailableProductsForBlock"
import { useFilteredProductsForTemplate } from "~/hooks/useFilteredProductsForTemplate"
import LineItemPreviewProductSelector from "./LineItemPreviewProductSelector"

export interface Props extends SelectProps {
  currentProductId: EntityId
  blockTemplateId: EntityId
  currentProductTemplateId: EntityId
  onProductChange: (id: EntityId) => void
}

const LineItemPreviewProductTemplateSelectorWrapper: React.FC<Props> = ({
  currentProductId,
  currentProductTemplateId,
  blockTemplateId,
  onProductChange,
}) => {
  const { blockTemplate } = useBlockTemplate(blockTemplateId)
  const { products: potentialProducts } = useCurrentAvailableProductsForBlock(
    blockTemplate.blockId
  )

  const { products } = useFilteredProductsForTemplate(
    potentialProducts,
    currentProductTemplateId
  )

  return (
    <LineItemPreviewProductSelector
      currentProductId={currentProductId}
      onProductChange={onProductChange}
      products={products}
    />
  )
}

export default LineItemPreviewProductTemplateSelectorWrapper
