import { Box, BoxProps, Text, VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { Element } from "react-scroll"
import { useProductPage } from "~/hooks/useProductPage"
import FoldedCard from "../layers/FoldedCard"
import StaticPage from "../layers/StaticPage"

export type PageType =
  | "standard"
  | "cover"
  | "inside-left"
  | "inside-right"
  | "back"

export const boxHeight = 80

const getPageType = (index: number, isFoldedCard: boolean): PageType => {
  if (isFoldedCard) {
    switch (index) {
      case 0:
        return "cover"
      case 1:
        return "inside-left"
      case 2:
        return "inside-right"
      case 3:
        return "back"
    }
  }

  return "standard"
}

const getPropsForPageType = (type: PageType) => {
  const defaultProps: BoxProps = {
    borderRadius: "md",
  }
  let props = defaultProps
  switch (type) {
    case "inside-left":
      props = {
        borderTopLeftRadius: "md",
        borderBottomLeftRadius: "md",
      }
      break
    case "inside-right":
      props = {
        borderTopRightRadius: "md",
        borderBottomRightRadius: "md",
      }
      break
  }

  return props
}

interface Props {
  lineItemId: EntityId
  pageId: EntityId
  index: number
  isActive: boolean
  isFoldedCard: boolean
  onClick: (id: EntityId) => void
}

const PageNavigationButton: React.FC<Props> = ({
  lineItemId,
  pageId,
  index,
  isActive,
  isFoldedCard,
  onClick,
}) => {
  const pageType: PageType = getPageType(index, isFoldedCard)
  const propsForPageType = getPropsForPageType(pageType)
  const { productPage } = useProductPage(pageId)
  const { width, height } = productPage!
  const ratio = width / height
  const boxWidth = boxHeight * ratio
  const pageName = productPage?.name || `Page ${index + 1}`

  const pageComponent = <StaticPage productPageId={pageId} isRealTime={true} />
  const page = isFoldedCard ? (
    <FoldedCard lineItemId={lineItemId}>{pageComponent}</FoldedCard>
  ) : (
    pageComponent
  )

  return (
    <Element name={`page-button-${pageId}`}>
      <VStack
        cursor="pointer"
        borderRadius="md"
        onClick={() => onClick(pageId)}
      >
        <Box
          borderWidth={3}
          {...propsForPageType}
          boxShadow="md"
          _hover={{ borderColor: isActive ? "primary.500" : "primary.100" }}
          borderColor={isActive ? "primary.500" : "transparent"}
          bg="gray.100"
          p={1}
        >
          <Box height={`${boxHeight}px`} minWidth={`${boxWidth}px`}>
            {page}
          </Box>
        </Box>
        <Box maxWidth="60px">
          <Text fontSize="sm" textAlign="center" lineHeight={1.1}>
            {pageName}
          </Text>
        </Box>
      </VStack>
    </Element>
  )
}

export default PageNavigationButton
