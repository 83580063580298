import { EntityId } from "@jackfruit/common"
import { motion } from "framer-motion"
import React, { useCallback, useRef } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useUpload } from "~/hooks/useUpload"
import {
  imageTransformationRotationStateFamily,
  isMultiImageRegionHovered,
} from "./atoms"
import { useClampCrop } from "./hooks/useClampCrop"
import { selectedRegion } from "./atoms"

interface Props {
  imageRegionId: EntityId
}

const EditorSvgCropper: React.FC<Props> = ({ imageRegionId }) => {
  const anchorRef = useRef<SVGRectElement>(null!)
  const { imageRegion } = useImageRegion(imageRegionId)!
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const imageTransformId = image?.imageTransformId!

  const setSelectedRegion = useSetRecoilState(selectedRegion)
  const handleSelectImageRegionForSidebar = useCallback(() => {
    setSelectedRegion({ type: "image", id: imageRegionId })
  }, [imageRegionId, setSelectedRegion])

  const setIsMultiImageRegionHovered = useSetRecoilState(
    isMultiImageRegionHovered(imageRegionId)
  )
  const handleMouseEnter = useCallback(() => {
    setIsMultiImageRegionHovered(true)
  }, [setIsMultiImageRegionHovered])

  const handleMouseLeave = useCallback(() => {
    setIsMultiImageRegionHovered(false)
  }, [setIsMultiImageRegionHovered])

  const rotation = useRecoilValue(
    imageTransformationRotationStateFamily(imageTransformId)
  )

  const imageUrl = image?.localUrl
  const { window } = imageRegion!

  const {
    containerRef,
    imageRef,
    translation: aTranslation,
    zoom: aZoom,
  } = useClampCrop(imageRegionId)

  const { x, y } = aTranslation
  const r = rotation
  const z = aZoom

  return (
    <svg
      onTouchStart={handleSelectImageRegionForSidebar}
      onClick={handleSelectImageRegionForSidebar}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ touchAction: "none", backgroundColor: "red" }}
      x={0}
      y={0}
      width={window.width}
      height={window.height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect
        ref={containerRef}
        x={0}
        y={0}
        width={window.width}
        height={window.height}
        fill="transparent"
      />
      <rect
        ref={anchorRef}
        x={0}
        y={0}
        width={window.width}
        height={window.height}
        fill="transparent"
      />
      <motion.image
        width={image?.width}
        height={image?.height}
        ref={imageRef}
        animate={{ rotate: r }}
        style={{
          touchAction: "none",
          cursor: "grab",
          transformBox: "fill-box",
          originX: "50%",
          originY: "50%",
          translateX: x,
          translateY: y,
          scale: z,
          rotate: r,
        }}
        xlinkHref={imageUrl}
      ></motion.image>
    </svg>
  )
}

export default EditorSvgCropper
