import { Box, HStack, Icon } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { AiFillExclamationCircle } from "react-icons/ai"

interface Props {}

const InvalidFilesWarning: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <HStack
      boxShadow="md"
      backgroundColor="red.100"
      color="red.600"
      borderRadius="full"
      spacing={1}
      py={1}
      px={2}
      fontSize="sm"
      _hover={{
        cursor: "pointer",
      }}
    >
      <Icon boxSize="16px" as={AiFillExclamationCircle} />
      <Box fontWeight={500}>
        {t("components.image-upload.InvalidFilesWarning.Description")}
      </Box>
    </HStack>
  )
}

export default InvalidFilesWarning
