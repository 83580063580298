import { SelectProps } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useCurrentAvailableProductsForPage } from "~/hooks/useCurrentAvailableProductsForPage"
import LineItemProductSelectorABTesting from "./LineItemProductSelectorABTesting"

export interface Props extends SelectProps {
  currentProductId: EntityId
  onProductChange: (id: EntityId) => void
  lineItemId: EntityId
}

const LineItemPreviewProductSelectorWrapper: React.FC<Props> = ({
  currentProductId,
  onProductChange,
  lineItemId,
}) => {
  const { products } = useCurrentAvailableProductsForPage()

  return (
    <LineItemProductSelectorABTesting
      currentProductId={currentProductId}
      onProductChange={onProductChange}
      products={products}
      lineItemId={lineItemId}
    />
  )
}

export default LineItemPreviewProductSelectorWrapper
