import { EntityId, PrinticularCategoryEntity } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { categorySelectors } from "~/redux/state/categories"
import { RootState } from "~/redux/store"

export const useCategories = (
  ids: EntityId[]
): { categories: PrinticularCategoryEntity[] } => {
  const categories = useSelector<RootState, PrinticularCategoryEntity[]>(
    (state: RootState) => categorySelectors.selectByIds(state, ids)
  )

  return { categories }
}
