import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { scroller } from "react-scroll"
import { useLineItem } from "~/hooks/useLineItem"
import { useProductPage } from "~/hooks/useProductPage"
import { currentSelectedPageIdFamily } from "../atoms"
import { useTranslation } from "react-i18next"

export const usePageEditorPagination = (
  lineItemId: EntityId,
  autoScroll = false
) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem

  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )
  const currentIndex = productPageIds.indexOf(currentSelectedPageId)
  const { productPage } = useProductPage(currentSelectedPageId)

  const hasPrevious = currentIndex > 0
  const hasNext = currentIndex < productPageIds.length - 1

  const handlePrevious = useCallback(() => {
    if (hasPrevious) {
      const pageId = productPageIds[currentIndex - 1]
      setCurrentSelectedPageId(pageId)
      if (autoScroll) {
        scroller.scrollTo(`page-button-${pageId}`, {
          containerId: "editorPaginationContainer",
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          horizontal: true,
        })
      }
    }
  }, [
    autoScroll,
    currentIndex,
    hasPrevious,
    productPageIds,
    setCurrentSelectedPageId,
  ])

  const handleNext = useCallback(() => {
    if (hasNext) {
      const pageId = productPageIds[currentIndex + 1]
      setCurrentSelectedPageId(pageId)
      if (autoScroll) {
        scroller.scrollTo(`page-button-${pageId}`, {
          containerId: "editorPaginationContainer",
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          horizontal: true,
        })
      }
    }
  }, [
    autoScroll,
    currentIndex,
    hasNext,
    productPageIds,
    setCurrentSelectedPageId,
  ])

  const currentPageLabel =
    productPage?.name ??
    `${t(
      `components.editor.common.pagination.hooks.usePageEditorPagination.Page`
    )} ${currentIndex + 1}`

  return {
    hasPrevious,
    hasNext,
    handlePrevious,
    handleNext,
    currentPageLabel,
  }
}
