/* eslint-disable mea/no-interactive-element-without-id-classname */
import { Box, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { Element, scroller } from "react-scroll"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "./atoms"
import PageNavigationButton from "./PageNavigationButton"

const getPageMarginLeft = (index: number, isFoldedCard: boolean) => {
  let marginLeft = index === 0 ? 0 : 3
  // folded card page inside right should not have margin left
  if (isFoldedCard && index === 2) {
    marginLeft = 0
  }

  return marginLeft
}

interface Props {
  lineItemId: EntityId
}

const PageEditorPageNavigation: React.FC<Props> = ({ lineItemId }) => {
  const {
    lineItem: { productPageIds },
    product,
  } = useLineItem(lineItemId)
  const isFoldedCard = product.metaData?.card?.folded ?? false

  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const onClick = (id: EntityId) => {
    setCurrentSelectedPageId(id)

    scroller.scrollTo(`page-button-${id}`, {
      containerId: "editorPaginationContainer",
      duration: 800,
      offset: -200,
      delay: 0,
      smooth: "easeInOutQuart",
      horizontal: true,
    })
  }

  return (
    <>
      <Box
        mx={3}
        mb={3}
        px={3}
        pt={3}
        boxShadow="lg"
        borderRadius="xl"
        backgroundColor="white"
      >
        <Flex
          as={Element}
          name="editorPaginationContainer"
          id="editorPaginationContainer"
          overflowX="scroll"
          pb={1}
          className="ScrollView"
        >
          {productPageIds.map((pageId, index) => {
            const ml = getPageMarginLeft(index, isFoldedCard)
            return (
              <Box ml={ml} key={pageId}>
                <PageNavigationButton
                  lineItemId={lineItemId}
                  pageId={pageId}
                  index={index}
                  isFoldedCard={isFoldedCard}
                  isActive={pageId === currentSelectedPageId}
                  onClick={onClick}
                />
              </Box>
            )
          })}
        </Flex>
      </Box>
    </>
  )
}

export default PageEditorPageNavigation
