import { useEffect, useRef, useState } from "react"
import { useWindowScroll, useWindowSize } from "react-use"
import { useRecoilValue } from "recoil"
import { ViewBox } from "~/interfaces/editors/ViewBox"
import { editorScrollPosition } from "../atoms"

export const useElementBox = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const { x: windowScrollX, y: windowScrollY } = useWindowScroll()
  const currentEditorScrollPosition = useRecoilValue(editorScrollPosition)
  const ref = useRef<Element>(null) as any
  const [box, setBox] = useState<ViewBox>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (ref.current) {
      const box = ref.current.getBoundingClientRect()
      setBox({
        x: box.left,
        y: box.top,
        width: box.width,
        height: box.height,
      })
    }
  }, [
    ref,
    windowWidth,
    windowHeight,
    windowScrollX,
    windowScrollY,
    currentEditorScrollPosition,
  ])

  return {
    ref,
    box,
  }
}
