import { Box, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { Element } from "react-scroll"
import { useMeasure } from "react-use"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBookNavigatorDisclosure } from "./hooks/usePhotoBookNavigatorDisclosure"
import PhotoBookNavigationWrapper from "./PhotoBookNavigationWrapper"
import PhotoBookPageEditorPager from "./PhotoBookPageEditorPager"

interface Props {
  lineItemId: EntityId
}

const PhotoBookEditorPageNavigation: React.FC<Props> = ({ lineItemId }) => {
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)

  const [ref, { width: navWidth }] = useMeasure<HTMLDivElement>()
  const { onOpen } = usePhotoBookNavigatorDisclosure()

  return (
    <>
      <PhotoBookPageEditorPager lineItemId={lineItemId} onOpen={onOpen} />
      <Box
        mx={3}
        mb={3}
        px={3}
        pt={3}
        ref={ref}
        boxShadow="lg"
        borderRadius="xl"
        backgroundColor="white"
      >
        <Flex
          as={Element}
          name="editorPaginationContainer"
          id="editorPaginationContainer"
          overflowX="scroll"
          pb={1}
          className="ScrollView"
        >
          <PhotoBookNavigationWrapper
            lineItemId={lineItemId}
            productPageIds={productPageIds}
            width={navWidth}
          />
        </Flex>
      </Box>
    </>
  )
}

export default PhotoBookEditorPageNavigation
