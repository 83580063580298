import { EntityId } from "@jackfruit/common"
import { useMemo } from "react"
import { floatsAreEqual, getProductRatio } from "~/services/Utils"
import { useLineItem } from "./useLineItem"
import { useProductTemplate } from "./useProductTemplate"

export const useAvailableProductTemplateVariants = (lineItemId: EntityId) => {
  const { lineItem, product } = useLineItem(lineItemId)
  const { productTemplate } = useProductTemplate(lineItem.productTemplateId!)

  const allVariants = productTemplate.variants

  const availableVariants = useMemo(() => {
    const { productRatio1, productRatio2 } = getProductRatio(product)

    return allVariants
      .map((variant, id) => ({ variant, id }))
      .filter(data => {
        const { variant } = data
        const firstPage = variant.pages[0]
        const pageRatio = firstPage.width / firstPage.height

        return (
          floatsAreEqual(productRatio1, pageRatio) ||
          floatsAreEqual(productRatio2, pageRatio)
        )
      })
  }, [allVariants, product])

  return { variants: availableVariants }
}
