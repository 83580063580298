/* eslint-disable mea/no-interactive-element-without-id-classname */
import {
  Box,
  IconButton,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiTrash2 } from "react-icons/fi"
import { Element } from "react-scroll"
import { useRecoilState, useSetRecoilState } from "recoil"
import ConfirmationModal from "~/components/ConfirmationModal"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { getOrientationDimensions } from "~/services/Utils"

import StaticPhotoBookPage from "../layers/StaticPhotoBookPage"
import BlankPage from "../photo-book/BlankPage"
import {
  currentSelectedPageIdFamily,
  isPhotoBookPageHoveredFamily,
} from "./atoms"
import { usePhotoBookActivePage } from "./hooks/usePhotoBookActivePage"
import { PhotoBookPage, usePhotoBookPages } from "./hooks/usePhotoBookPages"
import { boxHeight } from "./PageNavigationButton"
import PhotoBookPageName from "./PhotoBookPageName"

export type PagePosition = "left" | "right" | "middle"

interface Props {
  index: number
  lineItemId: EntityId
  productPageId?: EntityId
  type?: PhotoBookPage
  position?: PagePosition
}

const PhotoBookPageNavigationButton: React.FC<Props> = ({
  index,
  lineItemId,
  productPageId = "",
  type = "page",
  position = "middle",
}) => {
  const { t } = useTranslation()

  const {
    product,
    lineItem: { orientation, productPageIds },
  } = useLineItem(lineItemId)

  const setCurrentSelectedPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const { removePageSpread } = useLineItemActions()

  const { minImages } = product

  const { getBondedPageId } = usePhotoBookPages(lineItemId, productPageId)

  const { isActive } = usePhotoBookActivePage({
    productPageId,
    lineItemId,
    type,
  })

  const { isOpen, onClose, onOpen } = useDisclosure()

  //to bond two pages together and make the hover effect work
  const bondedPageId = getBondedPageId(type)

  const [isPhotoBookPageHovered, setIsPhotoBookPageHovered] = useRecoilState(
    isPhotoBookPageHoveredFamily(bondedPageId)
  )

  const borderHoverStyle = isPhotoBookPageHovered
    ? "primary.100"
    : "transparent"

  const isStart = type === "start"
  const isEnd = type === "end"
  const isProductPage = type === "page"

  const isLeftPage = position === "left"
  const isRightPage = position === "right"
  const isFirstPage = index === 0
  const canRemovePages = productPageIds.length > minImages
  const shouldShowDeleteButton =
    isRightPage && !isFirstPage && canRemovePages && isPhotoBookPageHovered

  const { width, height } = getOrientationDimensions(
    product.pixelWidth,
    product.pixelHeight,
    orientation ?? product.metaData?.orientationOverride ?? "landscape"
  )

  const ratio = width / height
  const boxWidth = boxHeight * ratio

  const handleDeleteSpread = () => {
    onOpen()
  }

  const selectPreviousPageSpread = () => {
    const previousPageSpreadIndex = productPageIds.indexOf(productPageId) - 2
    const previousPageSpreadFirstPageId =
      productPageIds[previousPageSpreadIndex]

    if (Boolean(previousPageSpreadFirstPageId)) {
      setCurrentSelectedPageId(previousPageSpreadFirstPageId)
    }
  }

  const handleDeleteSpreadConfirmation = () => {
    selectPreviousPageSpread()
    removePageSpread(lineItemId, productPageId)
    onClose()
  }

  return (
    <Element name={`page-button-${productPageId || type}`}>
      <VStack
        id={`page-button-${productPageId}`}
        cursor="pointer"
        borderLeftRadius={isLeftPage ? "md" : "unset"}
        borderRightRadius={isRightPage ? "md" : "unset"}
        onMouseEnter={() => setIsPhotoBookPageHovered(true)}
        onMouseLeave={() => setIsPhotoBookPageHovered(false)}
      >
        <Box
          py={1}
          mt={2}
          bg="white"
          borderWidth={2}
          borderRightWidth={isRightPage ? 2 : 0}
          borderLeftWidth={isLeftPage ? 2 : 0}
          boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
          borderLeftRadius={isLeftPage ? "md" : "unset"}
          borderRightRadius={isRightPage ? "md" : "unset"}
          borderColor={isActive ? "primary.500" : borderHoverStyle}
        >
          {isStart && (
            <BlankPage
              mx={1}
              h={`${boxHeight}px`}
              baseHeight={boxHeight}
              pageWidth={width}
              pageHeight={height}
            />
          )}

          {isEnd && (
            <BlankPage
              mx={1}
              h={`${boxHeight}px`}
              baseHeight={boxHeight}
              pageWidth={width}
              pageHeight={height}
            />
          )}

          {isProductPage && (
            <Box position="relative">
              <StaticPhotoBookPage productPageId={productPageId} />
              {shouldShowDeleteButton && (
                <Box position="absolute" top={1} right={2} zIndex={10}>
                  <Tooltip
                    hasArrow
                    label={t(
                      "components.editors.common.pagination.PhotoBookPageNavigationButton.removeItem"
                    )}
                    bg="gray.800"
                  >
                    <IconButton
                      id={`photobook-remove-page-spread-${productPageId}`}
                      boxShadow="md"
                      size="xs"
                      icon={<FiTrash2 />}
                      colorScheme="gray"
                      rounded="full"
                      aria-label="remove page spread from photobook"
                      onClick={handleDeleteSpread}
                    />
                  </Tooltip>
                </Box>
              )}
              <DeleteSpreadConfirmation
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={handleDeleteSpreadConfirmation}
              />
            </Box>
          )}
        </Box>
        <PhotoBookPageName
          productPageId={productPageId}
          productPageIds={productPageIds}
          w={`${boxWidth}px`}
          align="center"
          justify="center"
          type={type}
        />
      </VStack>
    </Element>
  )
}

export default PhotoBookPageNavigationButton

export const DeleteSpreadConfirmation: React.FC<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}> = ({ onClose, onConfirm, isOpen }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      title={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.Title"
      )}
      description={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.Message"
      )}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmLabel={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.ConfirmLabel"
      )}
      cancelLabel={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.CancelLabel"
      )}
      isCancelVisible={true}
      isOpen={isOpen}
      isCentered={true}
    />
  )
}
