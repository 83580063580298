import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRecoilCallback } from "recoil"
import {
  useGiftCertificate,
  useGiftCertificateActions,
} from "~/hooks/useGiftCertificate"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProduct } from "~/hooks/useProduct"
import { useProductImages } from "~/hooks/useProductImages"
import GiftCardEditorBackground from "~/images/GiftCardEditorBackground.svg"
import { scrollTo } from "~/services/Utils"
interface Props {
  lineItemId: EntityId
  onComplete: () => void
}

interface FormData {
  name: string
  email: string
  message: string
}

const GiftCardEditorContainer: React.FC<Props> = ({
  lineItemId,
  onComplete,
}) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { product } = useProduct(lineItem.productId)
  const { productImages } = useProductImages(lineItem.productId)
  const { giftCertificate } = useGiftCertificate(lineItem.giftCertificateId!)
  const { applyEditorChanges } = useProcessActions()
  const { updateGiftCertificate } = useGiftCertificateActions()
  const { remove } = useLineItemActions()

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: giftCertificate?.name ?? "",
      email: giftCertificate?.email ?? "",
      message: giftCertificate?.message ?? "",
    },
  })

  const { nextBlockType } = useNextActionableBlock("product-grid")

  const image = productImages[0]

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
  })

  const onContinue = useCallback(
    (data: FormData) => {
      const { name, email, message } = data

      updateGiftCertificate(
        giftCertificate!.id,
        giftCertificate!.productId,
        name,
        email,
        message
      )

      handleSaveChanges()

      if (!lineItem.isConfirmed) {
        // Handle scroll only on a new item creation
        scrollTo(nextBlockType)
      }
      onComplete()
    },
    [
      giftCertificate,
      handleSaveChanges,
      lineItem.isConfirmed,
      nextBlockType,
      onComplete,
      updateGiftCertificate,
    ]
  )

  const onCancel = useCallback(() => {
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    }
    onComplete()
  }, [lineItem.isConfirmed, lineItem.id, onComplete, remove])

  return (
    <Flex
      id="gift-card-editor-desktop"
      width="100vw"
      height="100vh"
      bg="blackAlpha.400"
    >
      <Flex
        m="auto"
        bg="white"
        flexGrow={0}
        maxW="100vw"
        w={{ md: "48rem", lg: "60rem" }}
      >
        <Flex flexGrow={3} p={10} flexDirection="column" maxHeight="100vh">
          <Heading mb={1}>
            {t("components.editors.giftcard.GiftCardEditor.Title")}
          </Heading>
          <Text mt={4} mb={8}>
            {t("components.editors.giftcard.GiftCardEditor.Subtitle")}
          </Text>

          <Box as="form" onSubmit={handleSubmit(onContinue)}>
            <FormControl id="gift-card-form-name" isRequired>
              <FormLabel fontWeight="bold" pb={2}>
                {t("components.editors.giftcard.GiftCardEditor.Name")}
              </FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return <Input w="full" {...field} />
                }}
              />
            </FormControl>
            <FormControl id="gift-card-form-email" isRequired>
              <FormLabel fontWeight="bold" pt={4} pb={2}>
                {t("components.editors.giftcard.GiftCardEditor.Email")}
              </FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => {
                  return <Input w="full" {...field} />
                }}
              />
            </FormControl>
            <FormControl id="gift-card-form-message" isRequired>
              <FormLabel fontWeight="bold" pt={4} pb={2}>
                {t("components.editors.giftcard.GiftCardEditor.Message")}
              </FormLabel>
              <Controller
                name="message"
                control={control}
                render={({ field }) => {
                  return <Textarea h={48} w="full" {...field} />
                }}
              />
            </FormControl>

            <Flex pt={4}>
              <Button
                ml="auto"
                backgroundColor="white"
                borderWidth={1}
                borderColor="color"
                color="grey"
                onClick={onCancel}
              >
                {t("components.editors.giftcard.GiftCardEditor.Cancel")}
              </Button>
              <Button
                ml={2}
                colorScheme="primary"
                color="primary.text"
                type="submit"
              >
                {t("components.editors.giftcard.GiftCardEditor.Confirm")}
              </Button>
            </Flex>
          </Box>
        </Flex>

        <Flex bgImage={GiftCardEditorBackground} bgSize="cover" flexGrow={0}>
          <Box m="auto" p={4} width={{ lg: 96, md: 80 }}>
            <Image
              src={image.url}
              maxW={{ lg: 80, md: 64 }}
              h={{ lg: 56 }}
              mx="auto"
            />
            <Text fontWeight="bold" py={2}>
              {product.name}
            </Text>
            {product.name !== product.shortDescription && (
              <Text pb={2}>{product.shortDescription}</Text>
            )}
            {Boolean(product.longDescription) && (
              <Text>{product.longDescription}</Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default GiftCardEditorContainer
