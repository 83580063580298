import {
  Flex,
  HStack,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react"
import { clamp } from "lodash"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi"
import { useRecoilState, useRecoilValue } from "recoil"
import { useTextRegion } from "~/hooks/useTextRegion"
import { selectedRegion, textRegionSizeFamily } from "./atoms"
import EditorButtonContainer from "./EditorButtonContainer"

const EditorTextZoomSlider: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useRecoilValue(selectedRegion)
  const { textRegion } = useTextRegion(id)
  const [size, setSize] = useRecoilState(textRegionSizeFamily(id))

  const { defaultSize } = textRegion!

  const zoomStep = 1
  const minZoom = defaultSize * 0.5
  const maxZoom = defaultSize * 1.5

  const onZoomUpPress = useCallback(() => {
    setSize(clamp(size + zoomStep, minZoom, maxZoom))
  }, [setSize, size, zoomStep, minZoom, maxZoom])

  const onZoomDownPress = useCallback(() => {
    setSize(clamp(size - zoomStep, minZoom, maxZoom))
  }, [setSize, size, zoomStep, minZoom, maxZoom])

  return (
    <EditorButtonContainer>
      <HStack spacing={3}>
        <IconButton
          id="scale-image-down"
          aria-label={t("components.editors.common.EditorZoomSlider.ScaleDown")}
          icon={<FiMinusCircle />}
          colorScheme="lightGray"
          variant="ghost"
          onClick={onZoomDownPress}
          borderRightRadius={0}
        />
        <Flex w="full" verticalAlign="center">
          <Slider
            className="scale-image-slider"
            colorScheme="primary"
            defaultValue={1}
            value={size}
            min={minZoom}
            max={maxZoom}
            step={zoomStep}
            onChange={setSize}
            size="lg"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb borderWidth={2} borderColor="primary.500" />
          </Slider>
        </Flex>
        <IconButton
          id="scale-image-up"
          aria-label={t("components.editors.common.EditorZoomSlider.ScaleUp")}
          icon={<FiPlusCircle />}
          colorScheme="lightGray"
          variant="ghost"
          onClick={onZoomUpPress}
          borderLeftRadius={0}
        />
      </HStack>
    </EditorButtonContainer>
  )
}

export default EditorTextZoomSlider
