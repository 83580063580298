import { useSelector } from "react-redux"
import { EntityId, PrintServiceProductPriceEntity } from "@jackfruit/common"
import { printServiceProductPricesSelector } from "~/redux/state/printServiceProductPrices"
import { RootState } from "~/redux/store"

export const useProductPrice = (id: EntityId) => {
  const productPrice = useSelector((state: RootState) =>
    printServiceProductPricesSelector.selectById(state, id)
  ) as PrintServiceProductPriceEntity

  return { productPrice }
}
