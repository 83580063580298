import { Stack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { FaChevronDown } from "react-icons/fa"
import { useSetRecoilState } from "recoil"
import LineItemProductSelector from "~/components/cart/LineItemProductSelector"
import LineItemProductTemplateSelector from "~/components/cart/LineItemProductTemplateSelector"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "../common/pagination/atoms"

interface Props {
  lineItemId: EntityId
}

const PrintEditorTopbarMobile: React.FC<Props> = ({ lineItemId }) => {
  const { lineItem } = useLineItem(lineItemId)
  const { updateProduct } = useLineItemActions()
  const setCurrentPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const handleProductChange = useCallback(
    (productId: EntityId) => {
      // navigate to the first page before changing product
      setCurrentPageId(lineItem.productPageIds[0])
      updateProduct(lineItemId, productId)
    },
    [lineItem.productPageIds, lineItemId, setCurrentPageId, updateProduct]
  )
  return (
    <Stack spacing={0}>
      {lineItem.isTemplate && (
        <LineItemProductTemplateSelector
          currentProductId={lineItem.productId}
          currentProductTemplateId={lineItem.productTemplateId!}
          onProductChange={handleProductChange}
          blockTemplateId={lineItem.blockTemplateId!}
          size="lg"
          icon={<FaChevronDown />}
          iconColor="primary.500"
        />
      )}
      {!lineItem.isTemplate && (
        <LineItemProductSelector
          bg="white"
          boxShadow="sm"
          currentProductId={lineItem!.productId}
          onProductChange={handleProductChange}
          size="lg"
          icon={<FaChevronDown />}
          iconColor="primary.500"
        />
      )}
    </Stack>
  )
}

export default PrintEditorTopbarMobile
