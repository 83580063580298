import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { photoBookPageIdsFamily } from "../atoms"
import { usePhotoBookNavigatorDisclosure } from "../pagination/hooks/usePhotoBookNavigatorDisclosure"

/*
    @param id: lineItemId
*/
export const usePhotoBookDnd = (id: EntityId) => {
  const { onClose } = usePhotoBookNavigatorDisclosure()
  const { updateProductPageIds } = useLineItemActions()
  const {
    lineItem: { productPageIds },
  } = useLineItem(id)

  const [photoBookPageIds, setPhotoBookPageIds] = useRecoilState(
    photoBookPageIdsFamily(id)
  )

  const rearrangeProductPages = useCallback(
    (sortedIds: EntityId[]) => {
      if (sortedIds.length === 0) {
        return null
      }
      updateProductPageIds(id, sortedIds)
    },
    [id, updateProductPageIds]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (active.id !== over?.id) {
        const oldIndex = photoBookPageIds.indexOf(active.id)
        const newIndex = photoBookPageIds.indexOf(over?.id!)
        const sortedPageIds = arrayMove(photoBookPageIds, oldIndex, newIndex)
        setPhotoBookPageIds(sortedPageIds)
      }
    },
    [photoBookPageIds, setPhotoBookPageIds]
  )

  const handleOnClose = useCallback(() => {
    if (photoBookPageIds !== productPageIds) {
      rearrangeProductPages(photoBookPageIds)
    }

    onClose()
  }, [onClose, photoBookPageIds, productPageIds, rearrangeProductPages])

  return { rearrangeProductPages, handleDragEnd, handleOnClose }
}
