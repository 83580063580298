import { As, Box, Flex, Icon, Text } from "@chakra-ui/react"
import { kebabCase } from "lodash"
import React from "react"

interface Props {
  label: string
  icon: As
  onClick?: () => void
  onTouchStart?: () => void
  onTouchEnd?: () => void
}

const EditorToolbarMobileButton: React.FC<Props> = ({
  label,
  icon,
  onClick,
  onTouchStart,
  onTouchEnd,
}) => {
  const kebabCaseLabel = kebabCase(label)
  return (
    <Flex
      id={`editor-mobile-toolbar-${kebabCaseLabel}`}
      py={2}
      w="full"
      as="button"
      direction="column"
      alignItems="center"
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onContextMenu={e => e.preventDefault()}
      transition="all 0.4s"
      color="gray.600"
      _active={{
        color: "primary.600",
        transform: "scale(0.85)",
      }}
    >
      <Box p={2} rounded="full">
        <Icon as={icon} boxSize={8} />
      </Box>
      <Box>
        <Text
          fontWeight="semibold"
          color="inherit"
          fontSize="0.8em"
          userSelect="none"
        >
          {label}
        </Text>
      </Box>
    </Flex>
  )
}

export default EditorToolbarMobileButton
