import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilValue } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageTransform } from "~/hooks/useImageTransform"
import {
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
} from "./atoms"

interface Props {
  imageId: EntityId
  isRealTime?: boolean
}

const ImageWithTransformations: React.FC<Props> = ({ imageId, isRealTime }) => {
  const { image } = useImage(imageId)
  const imageTransformId = image?.imageTransformId!
  const { imageTransform } = useImageTransform(imageTransformId)

  const realTimeTranslation = useRecoilValue(
    imageTransformationTranslationStateFamily(imageTransformId)
  )

  const realTimeZoom = useRecoilValue(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const realTimeRotation = useRecoilValue(
    imageTransformationRotationStateFamily(imageTransformId)
  )

  const { localUrl, width, height } = image!
  const { rotation, translation, zoom } = imageTransform!
  const cx = width / 2
  const cy = height / 2

  const { x, y } = isRealTime ? realTimeTranslation : translation
  const r = isRealTime ? realTimeRotation : rotation
  const z = isRealTime ? realTimeZoom : zoom

  return (
    <image
      width={image?.width}
      height={image?.height}
      style={{
        transformOrigin: `${cx}px ${cy}px`,
        transform: `translate(${x}px, ${y}px) scale(${z}) rotate(${r}deg)`,
        transformBox: `fill-box`,
      }}
      xlinkHref={localUrl}
    />
  )
}

export default React.memo(ImageWithTransformations)
