import { EntityId } from "@reduxjs/toolkit"
import { scroller } from "react-scroll"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "../atoms"

export const usePhotoBookPagination = (lineItemId: EntityId) => {
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)
  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const currentIndex = productPageIds.indexOf(currentSelectedPageId)

  const isCover = currentIndex === -1 && currentSelectedPageId === "cover"
  const isEnd = currentIndex === -1 && currentSelectedPageId === "end"

  const hasPrevious = currentIndex !== -1 || isEnd
  const hasNext = currentIndex !== -1 || isCover

  const handlePrevious = () => {
    if (hasPrevious) {
      const prevIndex = isEnd ? productPageIds.length - 2 : currentIndex - 2

      const pageId = prevIndex < 0 ? "cover" : productPageIds[prevIndex]

      setCurrentSelectedPageId(pageId)
      scroller.scrollTo(`page-button-${pageId}`, {
        containerId: "editorPaginationContainer",
        duration: 800,
        delay: 0,
        offset: -200,
        smooth: "easeInOutQuart",
        horizontal: true,
      })
    }
  }

  const handleNext = () => {
    if (hasNext) {
      const nextIndex = isCover ? 0 : currentIndex + 2
      const pageId =
        nextIndex >= productPageIds.length - 1
          ? "end"
          : productPageIds[nextIndex]

      setCurrentSelectedPageId(pageId)
      scroller.scrollTo(`page-button-${pageId}`, {
        containerId: "editorPaginationContainer",
        duration: 800,
        offset: -200,
        delay: 0,
        smooth: "easeInOutQuart",
        horizontal: true,
      })
    }
  }

  return { hasPrevious, hasNext, handlePrevious, handleNext }
}
