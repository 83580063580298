import { EntityId } from "@jackfruit/common"
import React from "react"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useUpload } from "~/hooks/useUpload"
import { ViewBox } from "~/interfaces/editors/ViewBox"
import EditorSvgCropper from "../EditorSvgCropper"
import PortalOverlay from "../PortalOverlay"
import EditorSvgUploadZoneProgress from "./EditorSvgUploadZoneProgress"

interface Props {
  imageRegionId: EntityId
  box: ViewBox
}

const EditorSvgUploadZoneNotEmpty: React.FC<Props> = ({
  imageRegionId,
  box,
}) => {
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)

  const imageIsReady = upload?.status !== "compressing"

  const content = !imageIsReady ? (
    <PortalOverlay box={box}>
      <EditorSvgUploadZoneProgress uploadId={upload!.id} />
    </PortalOverlay>
  ) : (
    <EditorSvgCropper imageRegionId={imageRegionId} />
  )

  return content
}

export default EditorSvgUploadZoneNotEmpty
