import { EntityId } from "@jackfruit/common"
import React from "react"
import { useCart } from "~/hooks/useCart"
import { useLineItem } from "~/hooks/useLineItem"
import { usePrintService } from "~/hooks/usePrintService"
import LineItemPreview from "./LineItemPreview"
import LineItemPreviewSkeleton from "./LineItemPreviewSkeleton"

interface Props {
  lineItemId: EntityId
}

const LineItemPreviewWrapper: React.FC<Props> = ({ lineItemId }) => {
  const { lineItem } = useLineItem(lineItemId)
  const { cart } = useCart()
  const { printService } = usePrintService(cart.printServiceId)
  const lineItemQuantityLimit = printService.printQuantityLimitPerLineItem
    ? printService.printQuantityLimitPerLineItem
    : 100000

  if (!lineItem.isReady) {
    return <LineItemPreviewSkeleton />
  }

  return (
    <LineItemPreview
      lineItemId={lineItemId}
      lineItemQuantityLimit={lineItemQuantityLimit}
    />
  )
}

export default LineItemPreviewWrapper
