import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useLineItemFrame } from "~/hooks/useLineItemFrame"

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const Overlay: React.FC<Props> = ({ lineItemId, children }) => {
  const { product } = useLineItem(lineItemId)
  const { frame } = useLineItemFrame(lineItemId)!
  const imageUrl = frame?.thumbnail

  return (
    <svg
      x={0}
      y={0}
      viewBox={`0 0 ${product.pixelWidth} ${product.pixelHeight}`}
    >
      {children}
      <image
        pointerEvents="none"
        x={-2}
        y={-2}
        width={product.pixelWidth + 4}
        height={product.pixelHeight + 4}
        xlinkHref={imageUrl}
      />
    </svg>
  )
}

export default Overlay
