import { EntityId } from "@reduxjs/toolkit"
import { useGesture } from "@use-gesture/react"
import {
  animate,
  AnimationPlaybackControls,
  useMotionValue,
} from "framer-motion"
import { clamp } from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useUpdateEffect } from "react-use"
import { useRecoilState, useRecoilValue } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useProduct } from "~/hooks/useProduct"
import { useUpload } from "~/hooks/useUpload"
import { getClampingValues, project, unproject } from "~/services/Utils"
import {
  editorCurrentProductId,
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
} from "../atoms"

export interface OnCropChangeProps {
  translation: {
    x: number
    y: number
  }
  zoom: number
  rotation: number
}

export const useClampCrop = (imageRegionId: EntityId) => {
  const { imageRegion } = useImageRegion(imageRegionId)!
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const imageTransformId = image?.imageTransformId!

  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)
  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )

  const [translation, setTranslation] = useRecoilState(
    imageTransformationTranslationStateFamily(imageTransformId)
  )

  const [zoom, setZoom] = useRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const [rotation] = useRecoilState(
    imageTransformationRotationStateFamily(imageTransformId)
  )

  const shouldInverseDimensions = Math.abs(rotation % 180) === 90

  const onCropChange = useCallback(
    (data: OnCropChangeProps) => {
      setTranslation(data.translation)
      setZoom(data.zoom)
    },
    [setTranslation, setZoom]
  )

  const [isUpdateRequired, setIsUpdateRequired] = useState(false)
  const [clampingX, setClampingX] = useState(false)
  const [clampingY, setClampingY] = useState(false)

  const containerRef = useRef<SVGRectElement>(null!)
  const imageRef = useRef<SVGImageElement>(null!)
  const animations = useRef<AnimationPlaybackControls[]>([])

  const ax = useMotionValue(translation.x)
  const ay = useMotionValue(translation.y)
  const azoom = useMotionValue(zoom)
  const arotation = useMotionValue(rotation)

  useUpdateEffect(() => {
    // update recoil state only in case we are not clamping anymore (animation is terminated)
    // and an updated is requied (an animation has been triggered and terminated)
    if (!clampingX && !clampingY && isUpdateRequired) {
      onCropChange({
        translation: { x: ax.get(), y: ay.get() },
        rotation: rotation,
        zoom: azoom.get(),
      })
      setIsUpdateRequired(false)
    }
  }, [
    ax,
    ay,
    azoom,
    clampingX,
    clampingY,
    rotation,
    onCropChange,
    isUpdateRequired,
  ])

  const regionDimensions = imageRegion!.window
  const imageDimensions = useMemo(() => {
    return {
      width: image!.width,
      height: image!.height,
    }
  }, [image])

  const clampImage = useCallback(
    async (executeCallback = true) => {
      setClampingX(true)
      setClampingY(true)
      const { maxX, minX, maxY, minY } = getClampingValues(
        regionDimensions,
        imageDimensions,
        azoom.get(),
        rotation
      )
      const { width: containerWidth, height: containerHeight } =
        regionDimensions
      const { width: imageWidth, height: imageHeight } = imageDimensions

      const isWidthOverflowing =
        (shouldInverseDimensions ? imageHeight : imageWidth) * azoom.get() >
        containerWidth
      const isHeightOverflowing =
        (shouldInverseDimensions ? imageWidth : imageHeight) * azoom.get() >
        containerHeight

      const currentTranslation = {
        x: ax.get(),
        y: ay.get(),
      }

      const { x, y } = currentTranslation
      const newTranslation = isClampingEnabled
        ? {
            x: isWidthOverflowing ? clamp(x, minX, maxX) : translation.x,
            y: isHeightOverflowing ? clamp(y, minY, maxY) : translation.y,
          }
        : currentTranslation

      animations.current = [
        animate(ax, newTranslation.x, {
          onComplete: () => {
            if (executeCallback) {
              setClampingX(false)
              // animation is over, trigger an update to sync recoil state
              setIsUpdateRequired(true)
            }
          },
        }),
        animate(ay, newTranslation.y, {
          onComplete: () => {
            if (executeCallback) {
              setClampingY(false)
              // animation is over, trigger an update to sync recoil state
              setIsUpdateRequired(true)
            }
          },
        }),
      ]
    },
    [
      regionDimensions,
      imageDimensions,
      azoom,
      rotation,
      shouldInverseDimensions,
      ax,
      ay,
      isClampingEnabled,
      translation.x,
      translation.y,
    ]
  )

  useEffect(() => {
    ax.set(translation.x)
    ay.set(translation.y)
    azoom.set(zoom)
    arotation.set(rotation)
    clampImage(false)
  }, [
    arotation,
    ax,
    ay,
    azoom,
    clampImage,
    rotation,
    translation.x,
    translation.y,
    zoom,
  ])

  useGesture(
    {
      onDrag: ({ offset: [x, y] }) => {
        animations.current.forEach(a => a.stop())
        const ctm = containerRef.current.getScreenCTM()
        const newTranslation = unproject(ctm, { x, y })
        ax.set(newTranslation.x)
        ay.set(newTranslation.y)
      },
      onDragEnd: clampImage,
    },
    {
      target: imageRef,
      eventOptions: { passive: false },
      drag: {
        from: () => {
          const ctm = containerRef.current.getScreenCTM()
          const point = project(ctm, { x: ax.get(), y: ay.get() })
          return [point.x, point.y]
        },
      },
      pinch: {
        distanceBounds: { min: 0 },
      },
    }
  )

  return {
    containerRef,
    imageRef,
    translation: { x: ax, y: ay },
    zoom: azoom,
    rotation: arotation,
    recoilTranslation: translation,
    recoilZoom: zoom,
  }
}
