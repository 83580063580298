import { EntityId } from "@jackfruit/common"
import { useLineItem } from "./useLineItem"
import { useLineItemFrame } from "./useLineItemFrame"

export const useCanSupportMultipleOrientations = (lineItemId: EntityId) => {
  const { lineItem, product } = useLineItem(lineItemId)
  const { hasOverlay, hasFrame, productImages } = useLineItemFrame(lineItemId)

  if (product.metaData?.orientationOverride) {
    return false
  }

  // square product
  if (product.pixelWidth === product.pixelHeight) {
    return false
  }

  if (lineItem.isTemplate) {
    return false
  }

  if (hasOverlay || hasFrame) {
    const frameImages = productImages.filter(
      productImage => productImage.metaData.frame
    )
    return frameImages.length > 1
  }

  // all other cases, we can change orientation on single print or canvas
  return true
}
