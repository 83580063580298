import { EntityId, PrintServiceEntity } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { printServicesSelector } from "~/redux/state/printServices"
import { RootState } from "~/redux/store"

export const usePrintService = (id: EntityId) => {
  const printService = useSelector((state: RootState) =>
    printServicesSelector.selectById(state, id)
  ) as PrintServiceEntity

  return { printService }
}
