import { EntityId } from "@jackfruit/common"
import React, { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { useImageTransform } from "~/hooks/useImageTransform"
import {
  imageTransformationMinZoomStateFamily,
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
} from "../atoms"

interface Props {
  imageTransformId: EntityId
}

const SyncReduxImageTransformToRecoil: React.FC<Props> = ({
  imageTransformId,
}) => {
  const { imageTransform } = useImageTransform(imageTransformId)
  const { translation, rotation, zoom, minZoom } = imageTransform!

  const setMinZoom = useSetRecoilState(
    imageTransformationMinZoomStateFamily(imageTransformId)
  )

  const setZoom = useSetRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const setTranslation = useSetRecoilState(
    imageTransformationTranslationStateFamily(imageTransformId)
  )

  const setRotation = useSetRecoilState(
    imageTransformationRotationStateFamily(imageTransformId)
  )

  useEffect(() => {
    setMinZoom(minZoom)
    setTranslation(translation)
    setRotation(rotation)
    setZoom(zoom)
  }, [
    translation,
    rotation,
    zoom,
    minZoom,
    setMinZoom,
    setTranslation,
    setRotation,
    setZoom,
  ])

  return null
}

export default SyncReduxImageTransformToRecoil
