import { Flex, Icon } from "@chakra-ui/react"
import React from "react"
import { TbAlignCenter, TbAlignLeft, TbAlignRight } from "react-icons/tb"
import { useRecoilState, useRecoilValue } from "recoil"
import { selectedRegion, textRegionAlignFamily } from "./atoms"

const EditorTextAlignmentSelector: React.FC = () => {
  const { id } = useRecoilValue(selectedRegion)
  const [align, setAlign] = useRecoilState(textRegionAlignFamily(id))

  return (
    <Flex gap={2}>
      <Icon
        backgroundColor={align === "left" ? "blue.100" : "gray.100"}
        borderRadius={5}
        width={8}
        height={8}
        my="auto"
        p={0.5}
        as={TbAlignLeft}
        cursor="pointer"
        onClick={() => setAlign("left")}
        className="align-left"
      />
      <Icon
        backgroundColor={align === "center" ? "blue.100" : "gray.100"}
        borderRadius={5}
        width={8}
        height={8}
        my="auto"
        p={0.5}
        as={TbAlignCenter}
        cursor="pointer"
        onClick={() => setAlign("center")}
        className="align-center"
      />
      <Icon
        backgroundColor={align === "right" ? "blue.100" : "gray.100"}
        borderRadius={5}
        width={8}
        height={8}
        my="auto"
        p={0.5}
        as={TbAlignRight}
        cursor="pointer"
        onClick={() => setAlign("right")}
        className="align-right"
      />
    </Flex>
  )
}

export default EditorTextAlignmentSelector
