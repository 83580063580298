import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  giftCertificates,
  giftCertificatesSelectors,
} from "~/redux/state/giftCertificates"
import { RootState } from "~/redux/store"

export const useGiftCertificate = (id: EntityId) => {
  const giftCertificate = useSelector((state: RootState) =>
    giftCertificatesSelectors.selectById(state, id)
  )

  return {
    giftCertificate,
  }
}

export const useGiftCertificateActions = () => {
  const dispatch = useDispatch()

  return {
    addGiftCertificate: useCallback(
      (
        id: EntityId,
        productId: EntityId,
        name: string,
        email: string,
        message: string
      ) => {
        dispatch(
          giftCertificates.actions.addOne({
            id,
            productId,
            name,
            email,
            message,
          })
        )
      },
      [dispatch]
    ),
    updateGiftCertificate: useCallback(
      (
        id: EntityId,
        productId: EntityId,
        name: string,
        email: string,
        message: string
      ) => {
        dispatch(
          giftCertificates.actions.updateOne({
            id,
            changes: {
              productId,
              name,
              email,
              message,
            },
          })
        )
      },
      [dispatch]
    ),
  }
}
