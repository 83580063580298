import { Button, ButtonProps } from "@chakra-ui/react"
import React from "react"

interface Props extends ButtonProps {
  isSelected?: boolean
}

const EditorButton: React.FC<Props> = ({
  children,
  isSelected = false,
  ...props
}) => {
  const fontColor = isSelected ? "primary.500" : "gray.500"
  return (
    <Button
      isFullWidth={true}
      justifyContent="flex-start"
      colorScheme="gray"
      color={fontColor}
      iconSpacing={3}
      {...props}
    >
      {children}
    </Button>
  )
}

export default EditorButton
