import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { textRegionsSelectors } from "~/redux/state/textRegions"
import { RootState } from "~/redux/store"

export const useTextRegions = (ids: EntityId[]) => {
  const textRegions = useSelector((state: RootState) =>
    textRegionsSelectors.selectByIds(state, ids)
  )

  return {
    textRegions,
  }
}
