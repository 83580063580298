import { EntityId } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { productTemplateSelectors } from "~/redux/state/productTemplates"
import { RootState } from "~/redux/store"

export const useProductTemplate = (id: EntityId) => {
  const productTemplate = useSelector((state: RootState) =>
    productTemplateSelectors.selectById(state, id)
  )!

  return { productTemplate }
}
