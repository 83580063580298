import { Center, CircularProgress } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useMemo } from "react"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useProductPage } from "~/hooks/useProductPage"
import { useTextRegions } from "~/hooks/useTextRegions"
import { useUpload } from "~/hooks/useUpload"
import { sanitizeTemplate } from "~/services/SvgUtils"
import ImageRegionPreview from "../ImageRegionPreview"
import { TextRegionPreview } from "../TextRegionPreview"

interface Props {
  productPageId: EntityId
  isRealTime?: Boolean
}

const StaticPage: React.FC<Props> = ({ productPageId, isRealTime = false }) => {
  const { productPage } = useProductPage(productPageId)
  const imageRegionIds = productPage!.imageRegionIds ?? []
  const textRegionIds = productPage!.textRegionIds ?? []
  const { imageRegion } = useImageRegion(imageRegionIds[0])
  const { textRegions } = useTextRegions(textRegionIds)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId ?? 0)

  const sanitizedSvg = useMemo(() => {
    return sanitizeTemplate(productPage!.svg || "")
  }, [productPage])

  // show a loader only if an image is present, but localUrl is not yet set
  const canShowImage = image && image.localUrl
  if (image && !canShowImage) {
    return (
      <Center height="full">
        <CircularProgress isIndeterminate={true} color="primary.400" />
      </Center>
    )
  }

  return (
    <svg viewBox={`0 0 ${productPage?.width} ${productPage?.height}`}>
      {imageRegionIds.map(imageRegionId => {
        return (
          <ImageRegionPreview
            key={imageRegionId}
            imageRegionId={imageRegionId}
            isRealTime={isRealTime}
          />
        )
      })}
      <g
        width="100%"
        height="100%"
        style={{ pointerEvents: "none" }}
        dangerouslySetInnerHTML={{
          __html: sanitizedSvg,
        }}
      ></g>
      {textRegions.map(textRegion => {
        return (
          <TextRegionPreview key={textRegion.id} textRegionId={textRegion.id} />
        )
      })}
    </svg>
  )
}

export default StaticPage
