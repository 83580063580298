import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRecoilCallback } from "recoil"
import {
  useGiftCertificate,
  useGiftCertificateActions,
} from "~/hooks/useGiftCertificate"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProduct } from "~/hooks/useProduct"
import { useProductImages } from "~/hooks/useProductImages"
import GiftCardEditorBackgroundMobile from "~/images/GiftCardEditorBackgroundMobile.svg"
import { scrollTo } from "~/services/Utils"
interface Props {
  lineItemId: EntityId
  onComplete: () => void
}

interface FormData {
  name: string
  email: string
  message: string
}

const GiftCardEditorContainerMobile: React.FC<Props> = ({
  lineItemId,
  onComplete,
}) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { product } = useProduct(lineItem.productId)
  const { productImages } = useProductImages(lineItem.productId)
  const { giftCertificate } = useGiftCertificate(lineItem.giftCertificateId!)
  const { applyEditorChanges } = useProcessActions()
  const { updateGiftCertificate } = useGiftCertificateActions()
  const { remove } = useLineItemActions()

  const { nextBlockType } = useNextActionableBlock("product-grid")

  const image = productImages[0]

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
  })

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: giftCertificate?.name ?? "",
      email: giftCertificate?.email ?? "",
      message: giftCertificate?.message ?? "",
    },
  })

  const onContinue = useCallback(
    (data: FormData) => {
      const { name, email, message } = data

      updateGiftCertificate(
        giftCertificate!.id,
        giftCertificate!.productId,
        name,
        email,
        message
      )

      handleSaveChanges()

      if (!lineItem.isConfirmed) {
        // Handle scroll only on a new item creation
        scrollTo(nextBlockType)
      }
      onComplete()
    },
    [
      giftCertificate,
      handleSaveChanges,
      lineItem.isConfirmed,
      nextBlockType,
      onComplete,
      updateGiftCertificate,
    ]
  )

  const onCancel = useCallback(() => {
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    }
    onComplete()
  }, [lineItem.isConfirmed, lineItem.id, onComplete, remove])

  return (
    <Flex
      id="gift-card-editor-mobile"
      width="100vw"
      height="100vh"
      bg="white"
      flexDirection="column"
      maxHeight="100vh"
    >
      <Flex
        bgImage={GiftCardEditorBackgroundMobile}
        bgSize="cover"
        flexGrow={0}
      >
        <Flex m="auto" w="full" flexDirection="column">
          <Image src={image.url} height={40} py={4} margin="auto" />
          <Text
            px={4}
            fontWeight="bold"
            margin="auto"
            width={`${image.width}px`}
          >
            {product.name}
          </Text>
          {product.name !== product.shortDescription && (
            <Text px={4} pb={2} margin="auto" width={`${image.width}px`}>
              {product.shortDescription}
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex mx={4} flexDirection="column" flexGrow={1}>
        <Heading fontSize={25} mb={1} pt={2}>
          {t("components.editors.giftcard.GiftCardEditor.Title")}
        </Heading>
        <Text fontSize={14} mb={2}>
          {t("components.editors.giftcard.GiftCardEditor.Subtitle")}
        </Text>
        <Box as="form" onSubmit={handleSubmit(onContinue)}>
          <FormControl id="gift-card-form-name-mobile" isRequired>
            <FormLabel fontSize={14} fontWeight="bold" pb={1}>
              {t("components.editors.giftcard.GiftCardEditor.Name")}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return <Input fontSize={14} w="full" {...field} />
              }}
            />
          </FormControl>
          <FormControl id="gift-card-form-email-mobile" isRequired>
            <FormLabel fontSize={14} fontWeight="bold" pt={2} pb={1}>
              {t("components.editors.giftcard.GiftCardEditor.Email")}
            </FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                return <Input fontSize={14} w="full" {...field} />
              }}
            />
          </FormControl>
          <FormControl id="gift-card-form-message-mobile" isRequired>
            <FormLabel fontSize={14} fontWeight="bold" pt={2} pb={1}>
              {t("components.editors.giftcard.GiftCardEditor.Message")}
            </FormLabel>
            <Controller
              name="message"
              control={control}
              render={({ field }) => {
                return <Textarea flexGrow={1} w="full" {...field} />
              }}
            />
          </FormControl>
          <Flex pt={4} pb={24}>
            <Button
              ml="auto"
              backgroundColor="white"
              borderWidth={1}
              borderColor="color"
              color="grey"
              onClick={onCancel}
            >
              {t("components.editors.giftcard.GiftCardEditor.Cancel")}
            </Button>
            <Button
              ml={2}
              colorScheme="primary"
              color="primary.text"
              type="submit"
            >
              {t("components.editors.giftcard.GiftCardEditor.Confirm")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default GiftCardEditorContainerMobile
