import { Box, Select } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { FiCheckCircle } from "react-icons/fi"
import { MdCropLandscape, MdCropPortrait } from "react-icons/md"
import { useAvailableProductTemplateVariants } from "~/hooks/useAvailableProductTemplateVariants"
import { useLineItem } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import EditorButton from "./EditorButton"

const MAX_VARIANT_FOR_BUTTON_MODE = 4

interface Props {
  lineItemId: EntityId
}

const EditorChangeProductTemplateVariantButtons: React.FC<Props> = ({
  lineItemId,
}) => {
  const process = useProcessActions()
  const { lineItem } = useLineItem(lineItemId)
  const currentProductTemplateVariantId = lineItem.productTemplateVariantId!
  const { variants: availableVariants } =
    useAvailableProductTemplateVariants(lineItemId)
  const count = availableVariants.length

  const handleChangeVariant = useCallback(
    (productTemplateVariantId: string) => {
      if (productTemplateVariantId !== currentProductTemplateVariantId) {
        process.updateLineItemProductTemplateVariant({
          lineItemId,
          productTemplateVariantId,
        })
      }
    },
    [currentProductTemplateVariantId, process, lineItemId]
  )

  if (availableVariants.length > MAX_VARIANT_FOR_BUTTON_MODE) {
    return (
      <Select
        fontWeight="semibold"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        color="gray.500"
        backgroundColor="white"
        colorScheme="gray"
        _hover={{
          cursor: "pointer",
        }}
        value={currentProductTemplateVariantId}
        onChange={event => handleChangeVariant(event.target.value)}
      >
        {availableVariants.map(({ variant }, index) => {
          const label = variant.name || `Variant ${index + 1}`

          return (
            <option key={index} value={variant.key}>
              {label}
            </option>
          )
        })}
      </Select>
    )
  }

  return (
    <>
      {availableVariants.map(({ variant }, index) => {
        const isSelected = currentProductTemplateVariantId === variant.key
        const isFirst = index === 0
        const isLast = index === count - 1
        const borderTopRadius = isFirst ? "md" : 0
        const borderBottomRadius = isLast ? "md" : 0
        const label = variant.name || `Variant ${index + 1}`
        const firstPage = variant.pages[0]
        const aspectRatio = firstPage.width / firstPage.height
        const icon = aspectRatio < 1 ? <MdCropPortrait /> : <MdCropLandscape />

        return (
          <EditorButton
            key={index}
            isSelected={isSelected}
            borderTopRadius={borderTopRadius}
            borderBottomRadius={borderBottomRadius}
            leftIcon={icon}
            data-product-template-variant-id={variant.key}
            onClick={() => handleChangeVariant(variant.key!)}
          >
            {label}
            {isSelected && (
              <Box position="absolute" right={4}>
                <FiCheckCircle />
              </Box>
            )}
          </EditorButton>
        )
      })}
    </>
  )
}

export default EditorChangeProductTemplateVariantButtons
