import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

interface Props extends FlexProps {
  height: number
  width: number
  image: string
  baseHeight?: number
  isMobile?: boolean
}

const Cover: React.FC<Props> = ({
  height,
  width,
  image,
  baseHeight = 80,
  isMobile = false,
  ...props
}) => {
  const ratio = width / height
  const boxHeight = baseHeight
  const boxWidth = baseHeight * ratio

  return (
    <Flex
      h={isMobile ? "full" : `${boxHeight}px`}
      w={isMobile ? "full" : `${boxWidth}px`}
      maxW="full"
      {...props}
    >
      <svg
        x={0}
        y={0}
        viewBox={`0 0 ${width} ${height}`}
        style={{ width: "100%", height: "100%" }}
      >
        <image
          x={0}
          y={0}
          width={width}
          height={height}
          xlinkHref={image}
          pointerEvents="none"
        ></image>
      </svg>
    </Flex>
  )
}

export default Cover
