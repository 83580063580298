import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { imageTransformationsSelectors } from "~/redux/state/imageTransformations"
import { RootState } from "~/redux/store"

export const useImageTransforms = (ids: EntityId[]) => {
  const imageTransforms = useSelector<RootState, ImageTransformationEntity[]>(
    state => imageTransformationsSelectors.selectByIds(state, ids)
  )

  return { imageTransforms }
}
