import { Box, Flex, Heading, Text, Fade, Image } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useProcessActions } from "~/hooks/useProcessActions"
import { ReactComponent as DropzoneDefaultImageSVG } from "~/images/CartUpload.svg"
import { Gtm } from "~/services/Gtm"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import InvalidFilesWarning from "../image-upload/InvalidFilesWarning"
import { useCurrentPageId } from "~/hooks/useCurrentPageId"
import { Trans, useTranslation } from "react-i18next"
import { AssetEntity } from "@jackfruit/common"

interface Props {
  customDropzoneImage?: AssetEntity
}

const CartUpload: React.FC<Props> = ({ customDropzoneImage }) => {
  const { t } = useTranslation()
  const pageId = useCurrentPageId()
  const process = useProcessActions()
  const [isDropzoneHover, setIsDropzoneHover] = useState(false)
  const [showImageWarning, setShowImageWarning] = useState(false)

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      Gtm.fireFileUploadEvent(acceptedFiles.length)
      setIsDropzoneHover(false)
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i]
        process.createUpload({ pageId, file })
      }

      if (rejectedFiles.length > 0) {
        setShowImageWarning(true)
        setTimeout(() => setShowImageWarning(false), 4000)
      }
    },
    [pageId, process]
  )

  const onDragEnter = useCallback(() => {
    setIsDropzoneHover(true)
  }, [setIsDropzoneHover])

  const onDragLeave = useCallback(() => {
    setIsDropzoneHover(false)
  }, [setIsDropzoneHover])

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    accept: UPLOAD_ACCEPTED_FILE_TYPES,
  })

  const hoverStyles = isDragReject
    ? {
        borderColor: "red.400",
        backgroundColor: "red.100",
      }
    : {
        borderColor: "upload.300",
        backgroundColor: "upload.50",
      }

  const dropZoneImageComponent =
    customDropzoneImage && customDropzoneImage.path ? (
      <Image
        id="custom-dropzone-image"
        width="auto"
        height="auto"
        ignoreFallback={true}
        src={customDropzoneImage.path}
        alt={t("components.cart.CartUpload.Upload")}
      />
    ) : (
      <DropzoneDefaultImageSVG />
    )

  return (
    <>
      <Flex
        position="relative"
        w="full"
        py={[4, 10]}
        px={4}
        borderWidth="3px"
        borderRadius="lg"
        cursor="pointer"
        alignItems="center"
        borderStyle="dashed"
        flexDirection="column"
        justifyContent="center"
        transition="all ease-out .3s"
        bg={isDropzoneHover ? hoverStyles.backgroundColor : "gray.50"}
        borderColor={isDropzoneHover ? hoverStyles.borderColor : "gray.200"}
        _hover={hoverStyles}
        h="300px"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {dropZoneImageComponent}
        <Box textAlign="center">
          <Heading
            textTransform="none"
            color="secondary.500"
            fontSize="lg"
            as="h5"
            mb={2}
            mt={4}
          >
            {t("components.cart.CartUpload.DragNDrop")}
          </Heading>
          <Trans i18nKey="components.cart.CartUpload.DeviceWrapper" t={t}>
            <Text id="import-files-link" color="primary.500" as="u" />
          </Trans>
        </Box>
        <Fade in={showImageWarning}>
          <Flex
            justifyContent="center"
            left={0}
            right={0}
            bottom="10px"
            position="absolute"
            width="100%"
          >
            <InvalidFilesWarning />
          </Flex>
        </Fade>
      </Flex>
    </>
  )
}

export default CartUpload
