import { EntityId } from "@jackfruit/common"
import React from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useProductPage } from "~/hooks/useProductPage"

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const FoldedCard: React.FC<Props> = ({ lineItemId, children }) => {
  const { product, lineItem } = useLineItem(lineItemId)
  const firstPageId = lineItem?.productPageIds[0]
  const { productPage } = useProductPage(firstPageId)

  const { metaData } = product

  const padding = metaData?.card?.border.size ?? 0

  const productWidth = product.pixelWidth ?? 0
  const productHeight = product.pixelHeight ?? 0
  const pageWidth = productPage?.width ?? 0
  const pageHeight = productPage?.height ?? 0
  const viewBoxWidth = productWidth + padding * 2
  const viewBoxHeight = productHeight + padding * 2

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    >
      <rect
        x={0}
        y={0}
        width={viewBoxWidth}
        height={viewBoxHeight}
        fill="white"
      />

      <svg
        x={padding}
        y={padding}
        width={productWidth}
        height={productHeight}
        viewBox={`0 0 ${productWidth} ${productHeight}`}
      >
        <svg
          viewBox={`0 0 ${pageWidth} ${pageHeight}`}
          preserveAspectRatio="xMidYMin slice"
        >
          {children}
        </svg>
      </svg>
    </svg>
  )
}

export default FoldedCard
