import { Box, Button, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { debounce, last } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { scroller } from "react-scroll"
import { useSetRecoilState } from "recoil"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "./atoms"

interface Props {
  lineItemId: EntityId
}

const PhotoBookAddSpreadButton: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()
  const { product, lineItem } = useLineItem(lineItemId)
  const { insertPageSpreadBefore } = useLineItemActions()
  const setCurrentSelectedPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const { productPageIds } = lineItem
  const { maxImages } = product

  const lastPageId = last(productPageIds)
  const currentPageCount = lineItem.productPageIds.length

  const afterInsert = debounce((id: EntityId) => {
    scroller.scrollTo(`page-button-${id}`, {
      containerId: "editorPaginationContainer",
      duration: 800,
      offset: -200,
      delay: 0,
      smooth: "easeInOutQuart",
      horizontal: true,
    })
    setCurrentSelectedPageId(id)
  }, 100)

  const handleInsertPageSpread = () => {
    if (currentPageCount < maxImages) {
      insertPageSpreadBefore(lineItemId, lastPageId!, afterInsert)
    }
  }

  return (
    <Box pl={3} pt={2} alignSelf="start">
      <Flex
        px={6}
        height="92px"
        borderWidth={1}
        borderColor="primary.50"
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        bg="gray.50"
      >
        <Button
          colorScheme="primary"
          size="sm"
          onClick={handleInsertPageSpread}
          id="photobook-add-spread"
        >
          {t(
            "components.editors.common.pagination.PhotoBookPageName.AddSpread"
          )}
        </Button>
      </Flex>
    </Box>
  )
}

export default PhotoBookAddSpreadButton
