import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiCamera } from "react-icons/fi"
import EditorButton from "./EditorButton"
import EditorChangeImageWrapper from "./EditorChangeImageWrapper"

interface Props {
  lineItemId: EntityId
  imageRegionId: EntityId
  variant?: string
}

const EditorChangeImageButton: React.FC<Props> = ({
  imageRegionId,
  lineItemId,
}) => {
  const { t } = useTranslation()
  return (
    <EditorChangeImageWrapper
      imageRegionId={imageRegionId}
      lineItemId={lineItemId}
      width="full"
    >
      <EditorButton leftIcon={<FiCamera />} disabled={!imageRegionId}>
        {t("components.editors.common.EditorChangeImageButton.ChangePhoto")}
      </EditorButton>
    </EditorChangeImageWrapper>
  )
}

export default EditorChangeImageButton
