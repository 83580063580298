import React from "react"

interface Props {}

const OrientIcon: React.FC<Props> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 431.42 363.2"
      {...props}
    >
      <defs></defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <rect
            x={16.5}
            y={16.5}
            width={227.5}
            height={330.2}
            rx={27.87}
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={33}
          />
          <path
            strokeLinecap="round"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={33}
            d="M279.5 346.7H244v-35.5"
          />
          <path
            d="M244 266.12V110.88a2.26 2.26 0 012.25-2.26h153.51a15.16 15.16 0 0115.16 15.16v207.76a15.16 15.16 0 01-15.16 15.16H302"
            strokeDasharray="69.58 45.08"
            strokeLinecap="round"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={33}
          />
          <path
            fill="currentColor"
            stroke="currentColor"
            d="M133.48 101.24c5.25 1.55 8.57 5.17 12.3 8.31q19.14 16.13 38.22 32.3c2.69 2.27 4.45 5 4.42 8.25 0 4.5-2.44 8-7.23 9.93a13.65 13.65 0 01-13.62-1.28c-2.84-2-5.44-4.25-8.07-6.46-5.39-4.5-10.72-9-16.52-14V263.47c0 5.53-5.68 10.27-12.23 10.38-6.1.1-11.64-3.1-12.92-8.07a8.64 8.64 0 01-.26-2.14V138.43c-.83.64-1.35 1-1.84 1.44-7 5.88-13.9 11.77-20.89 17.63a14.54 14.54 0 01-9.81 3.63c-5.23-.1-9.26-2.11-11.56-6.11s-1.8-7.95 1.49-11.53a27.68 27.68 0 012.44-2.4q18.67-15.81 37.37-31.58c3.74-3.15 7-6.76 12.3-8.31z"
          />
        </g>
      </g>
    </svg>
  )
}

export default OrientIcon
