import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useImageRegions } from "./useImageRegions"
import { useImages } from "./useImages"
import { useImageTransforms } from "./useImageTransforms"
import { useLineItem } from "./useLineItem"
import { useProductPages } from "./useProductPages"
import { useUploadEntities } from "./useUploadEntities"

export const useAllImageTransformsForLineItem = (lineItemId: EntityId) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { productPages } = useProductPages(productPageIds)
  const allImageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )
  const { imageRegions } = useImageRegions(allImageRegionIds)
  const allUploadIds = imageRegions
    .filter(imageRegion => imageRegion.uploadId)
    .map(imageRegion => imageRegion.uploadId as EntityId)

  const { uploads } = useUploadEntities(allUploadIds)

  const allImageIds = uploads.map(upload => upload.imageId)
  const { images } = useImages(allImageIds)

  const allImageTransformIds = images.map(image => image.imageTransformId)
  const { imageTransforms } = useImageTransforms(allImageTransformIds)

  return { imageTransforms }
}
