import { SimpleGrid } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiCamera, FiRotateCcw, FiZoomIn, FiZoomOut } from "react-icons/fi"
import { useRecoilValue } from "recoil"
import OrientIcon from "~/components/custom-icons/OrientIcon"
import { useSvgCropperToolBarHandlers } from "~/components/editors/common/hooks/useSvgCropperToolBarHandlers"
import { useCanSupportMultipleOrientations } from "~/hooks/useCanSupportMultipleOrientations"
import EditorChangeImageWrapper from "./EditorChangeImageWrapper"
import EditorToolbarMobileButton from "./EditorToolbarMobileButton"
import { selectedRegion } from "./atoms"

interface Props {
  lineItemId: EntityId
}

const EditorSvgCropperMobileToolBar: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()
  const { id: imageRegionId } = useRecoilValue(selectedRegion)

  const supportsMultipleOrientations =
    useCanSupportMultipleOrientations(lineItemId)

  const {
    handleRotateCcw,
    handleZoomIn,
    handleZoomOut,
    handleStartZoomingIn,
    handleStartZoomingOut,
    handleStopZoomingIn,
    handleStopZoomingOut,
    handleToggleOrientation,
  } = useSvgCropperToolBarHandlers(lineItemId, imageRegionId)

  const columns = supportsMultipleOrientations ? 5 : 4

  return (
    <SimpleGrid columns={columns}>
      <EditorChangeImageWrapper
        imageRegionId={imageRegionId}
        lineItemId={lineItemId}
      >
        <EditorToolbarMobileButton
          label={t(
            "components.editors.common.EditorSvgCropperMobileToolBar.Replace"
          )}
          icon={FiCamera}
        />
      </EditorChangeImageWrapper>
      {supportsMultipleOrientations && (
        <EditorToolbarMobileButton
          label={t(
            "components.editors.common.EditorSvgCropperMobileToolBar.Orient"
          )}
          icon={OrientIcon}
          onClick={handleToggleOrientation}
        />
      )}
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBar.Rotate"
        )}
        icon={FiRotateCcw}
        onClick={handleRotateCcw}
      />
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBar.ZoomIn"
        )}
        icon={FiZoomIn}
        onClick={handleZoomIn}
        onTouchStart={handleStartZoomingIn}
        onTouchEnd={handleStopZoomingIn}
      />
      <EditorToolbarMobileButton
        label={t(
          "components.editors.common.EditorSvgCropperMobileToolBar.ZoomOut"
        )}
        icon={FiZoomOut}
        onClick={handleZoomOut}
        onTouchStart={handleStartZoomingOut}
        onTouchEnd={handleStopZoomingOut}
      />
    </SimpleGrid>
  )
}

export default React.memo(EditorSvgCropperMobileToolBar)
