import { EntityId } from "@jackfruit/common"
import { last } from "lodash"
import React, { useEffect } from "react"
import { useRecoilState } from "recoil"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { photoBookPageIdsFamily } from "../atoms"
import { usePhotoBookDnd } from "../hooks/usePhotoBookDnd"
import { usePhotoBookNavigatorDisclosure } from "./hooks/usePhotoBookNavigatorDisclosure"
import NavigationDrawer from "./NavigationDrawer"
import PhotoBookMobilePageList from "./PhotoBookMobilePageList"

interface Props {
  lineItemId: EntityId
}

const PhotoBookEditorMobilePageNavigation: React.FC<Props> = ({
  lineItemId,
}) => {
  const { isOpen } = usePhotoBookNavigatorDisclosure()
  const { handleDragEnd, handleOnClose } = usePhotoBookDnd(lineItemId)
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)

  const { insertPageSpreadBefore } = useLineItemActions()

  const [photoBookPageIds, setPhotoBookPageIds] = useRecoilState(
    photoBookPageIdsFamily(lineItemId)
  )

  useEffect(() => {
    setPhotoBookPageIds(productPageIds)
  }, [productPageIds, setPhotoBookPageIds])

  const lastPageId = last(productPageIds)
  const handleInsertPageSpread = () => {
    insertPageSpreadBefore(lineItemId, lastPageId!)
  }

  return (
    <NavigationDrawer isOpen={isOpen} onClose={handleOnClose}>
      <PhotoBookMobilePageList
        pageIds={photoBookPageIds}
        handleDragEnd={handleDragEnd}
        lineItemId={lineItemId}
        onInsertPages={handleInsertPageSpread}
      />
    </NavigationDrawer>
  )
}

export default PhotoBookEditorMobilePageNavigation
