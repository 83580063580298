import { Box, Button, ButtonProps, HStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { ViewType } from "../Editor"
import { useTranslation } from "react-i18next"

interface Props extends ButtonProps {
  onContinue: () => void
  onCancel: () => void
  viewType: ViewType
}

const EditorActionButtons: React.FC<Props> = ({
  onContinue,
  onCancel,
  viewType,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const isDesktop = viewType === "desktop"
  const cancelButtonColor = isDesktop ? "primary.500" : "gray.600"
  const cancelButtonBgColor = isDesktop ? "transparent" : "gray.100"

  const handleContinue = useCallback(() => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 100)
    setTimeout(onContinue, 100)
  }, [onContinue])

  return (
    <Box>
      <HStack>
        <Button
          id="editor-cancel-button"
          w="full"
          color={cancelButtonColor}
          bg={cancelButtonBgColor}
          onClick={onCancel}
          {...props}
        >
          {t("components.editors.common.EditorActionButtons.Cancel")}
        </Button>
        <Button
          id="editor-continue-button"
          w="full"
          colorScheme="primary"
          color="primary.text"
          onClick={handleContinue}
          isLoading={isLoading}
          {...props}
        >
          {t("components.editors.common.EditorActionButtons.Continue")}
        </Button>
      </HStack>
    </Box>
  )
}

export default EditorActionButtons
