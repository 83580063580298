import { Button, Center } from "@chakra-ui/react"
import { BlockType } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import ScrollLinkWrapper from "./ScrollLinkWrapper"

interface Props {
  currentBlock: BlockType
}

const NextButton: React.FC<Props> = ({ currentBlock }) => {
  const { t } = useTranslation()

  return (
    <Center marginTop={10}>
      <ScrollLinkWrapper
        toType="nextAction"
        currentBlock={currentBlock}
        offset={0}
      >
        <Button
          id={`p-${currentBlock}-next`}
          colorScheme="primary"
          color="primary.text"
        >
          {t("components.scroll-wrappers.NextButton.Next")}
        </Button>
      </ScrollLinkWrapper>
    </Center>
  )
}

export default NextButton
