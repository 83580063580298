import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilValue } from "recoil"
import { DesktopToolBarExperiment } from "../print/PrintEditor"
import { selectedRegion } from "./atoms"
import EditorSvgCropperDesktopToolBarImage from "./EditorSvgCropperDesktopToolBarImage"

const DesktopTopbar: React.FC<{ lineItemId: EntityId }> = ({ lineItemId }) => {
  const selectedRegionData = useRecoilValue(selectedRegion)

  const desktopToolBarExperiment = DesktopToolBarExperiment.showInSidebar
  // useGoogleOptimize(settings.googleOptimizeExperimentIdSix, [
  //   DesktopToolBarExperiment.showInSidebar,
  //   DesktopToolBarExperiment.showInTopbar,
  // ]) ?? DesktopToolBarExperiment.showInSidebar

  if (desktopToolBarExperiment !== DesktopToolBarExperiment.showInTopbar) {
    return null
  }

  if (selectedRegionData?.type === "image") {
    return <EditorSvgCropperDesktopToolBarImage lineItemId={lineItemId} />
  }

  return null
}

export default DesktopTopbar
