import React from "react"
import { Dimensions } from "~/interfaces/editors/Dimensions"

const edgeColor = "#2D3748"
const wrapColor = "#f1f2f2"
const wrapOpacity = 0.6
const cornerOpacity = 0.9

interface WrappingProps {
  innerArea: Dimensions
  safePadding: number
  wrapPadding: number
  color?: string
  opacity?: number
}

const Wrapping: React.FC<WrappingProps> = ({
  innerArea,
  safePadding,
  wrapPadding,
  color = "transparent",
  opacity = 1,
}) => {
  const halfPadding = safePadding + wrapPadding
  const totalPadding = halfPadding * 2
  const totalWidth = innerArea.width + totalPadding
  const totalHeight = innerArea.height + totalPadding
  const safeAreaWidth = innerArea.width
  const safeAreaHeight = innerArea.height
  const strokeWidth = Math.max(totalWidth, totalHeight) / 400
  const dashArray = `${strokeWidth * 4} ${strokeWidth * 4}`

  const topRect = (
    <rect
      x={halfPadding}
      y={0}
      width={safeAreaWidth}
      height={halfPadding}
      fill={color}
      opacity={opacity}
      pointerEvents="none"
    />
  )

  const bottomRect = (
    <rect
      x={halfPadding}
      y={halfPadding + safeAreaHeight}
      width={safeAreaWidth}
      height={halfPadding}
      fill={color}
      opacity={opacity}
      pointerEvents="none"
    />
  )

  const leftRect = (
    <rect
      x={0}
      y={halfPadding}
      width={halfPadding}
      height={safeAreaHeight}
      fill={color}
      opacity={opacity}
      pointerEvents="none"
    />
  )

  const rightRect = (
    <rect
      x={halfPadding + safeAreaWidth}
      y={halfPadding}
      width={halfPadding}
      height={safeAreaHeight}
      fill={color}
      opacity={opacity}
      pointerEvents="none"
    />
  )

  const topLeftSquare = (
    <rect
      x={0}
      y={0}
      width={halfPadding}
      height={halfPadding}
      fill={wrapColor}
      opacity={cornerOpacity}
      pointerEvents="none"
    />
  )

  const bottomLeftSquare = (
    <rect
      x={0}
      y={halfPadding + safeAreaHeight}
      width={halfPadding}
      height={halfPadding}
      fill={wrapColor}
      opacity={cornerOpacity}
      pointerEvents="none"
    />
  )

  const topRightSquare = (
    <rect
      x={halfPadding + safeAreaWidth}
      y={0}
      width={halfPadding}
      height={halfPadding}
      fill={wrapColor}
      opacity={cornerOpacity}
      pointerEvents="none"
    />
  )

  const bottomRightSquare = (
    <rect
      x={halfPadding + safeAreaWidth}
      y={halfPadding + safeAreaHeight}
      width={halfPadding}
      height={halfPadding}
      fill={wrapColor}
      opacity={cornerOpacity}
      pointerEvents="none"
    />
  )

  const leftLine = (
    <line
      x1={halfPadding}
      y1={0}
      x2={halfPadding}
      y2={totalHeight}
      strokeWidth={strokeWidth}
      stroke={edgeColor}
      strokeDasharray={dashArray}
      pointerEvents="none"
    />
  )

  const rightLine = (
    <line
      x1={totalWidth - halfPadding}
      y1={0}
      x2={totalWidth - halfPadding}
      y2={totalHeight}
      strokeWidth={strokeWidth}
      stroke={edgeColor}
      strokeDasharray={dashArray}
      pointerEvents="none"
    />
  )

  const topLine = (
    <line
      x1={0}
      y1={halfPadding}
      x2={totalWidth}
      y2={halfPadding}
      strokeWidth={strokeWidth}
      stroke={edgeColor}
      strokeDasharray={dashArray}
      pointerEvents="none"
    />
  )

  const bottomLine = (
    <line
      x1={0}
      y1={totalHeight - halfPadding}
      x2={totalWidth}
      y2={totalHeight - halfPadding}
      strokeWidth={strokeWidth}
      stroke={edgeColor}
      strokeDasharray={dashArray}
      pointerEvents="none"
    />
  )

  const border = (
    <rect
      x={strokeWidth / 2}
      y={strokeWidth / 2}
      width={totalWidth - strokeWidth}
      height={totalHeight - strokeWidth}
      fill="none"
      strokeWidth={strokeWidth}
      stroke={edgeColor}
      pointerEvents="none"
    />
  )

  return (
    <>
      {topLeftSquare}
      {bottomLeftSquare}
      {topRightSquare}
      {bottomRightSquare}
      {topRect}
      {bottomRect}
      {leftRect}
      {rightRect}
      {leftLine}
      {rightLine}
      {topLine}
      {bottomLine}
      {border}
    </>
  )
}

export interface Props {
  innerArea: Dimensions
  safePadding: number
  wrapPadding: number
  children: React.ReactNode
}

const CanvasWrapOverlay: React.FC<Props> = ({
  innerArea,
  safePadding,
  wrapPadding,
  children,
}) => {
  const halfPadding = safePadding + wrapPadding
  const totalPadding = halfPadding * 2
  const totalWidth = innerArea.width + totalPadding
  const totalHeight = innerArea.height + totalPadding

  return (
    <svg
      x={0}
      y={0}
      style={{
        width: "100%",
        height: "100%",
      }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`${0} ${0} ${totalWidth} ${totalHeight}`}
    >
      {children}
      <Wrapping
        innerArea={innerArea}
        safePadding={safePadding}
        wrapPadding={wrapPadding}
        opacity={wrapOpacity}
        color={wrapColor}
      />
    </svg>
  )
}

export default CanvasWrapOverlay
