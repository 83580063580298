import { EntityId } from "@reduxjs/toolkit"
import { last } from "lodash"
import { useRecoilValue } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { currentSelectedPageIdFamily } from "../atoms"
import { PhotoBookPage } from "./usePhotoBookPages"

interface Options {
  lineItemId: EntityId
  productPageId: EntityId
  type: PhotoBookPage
}

export const usePhotoBookActivePage = ({
  lineItemId,
  productPageId,
  type,
}: Options) => {
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)

  const currentSelectedPageId = useRecoilValue(
    currentSelectedPageIdFamily(lineItemId)
  )

  //Check if page is the current selected page
  const isSamePage = currentSelectedPageId === productPageId

  //if page is the cover page, return true if the selected page is the cover page
  if (type === currentSelectedPageId) {
    return { isActive: true }
  }
  if (currentSelectedPageId === "cover" && type !== "cover") {
    return { isActive: false }
  }

  //For the start page, return true if the selected page is the first page
  if (type === "start") {
    return { isActive: productPageIds[0] === currentSelectedPageId }
  }

  //For the end page, return true if the selected page is the last page
  //or is the end blank page
  if (type === "end") {
    return {
      isActive:
        last(productPageIds) === currentSelectedPageId ||
        type === currentSelectedPageId,
    }
  }

  //If the current selected page is the end blank page
  //then return true if the page is the last page
  if (currentSelectedPageId === "end") {
    return { isActive: productPageId === last(productPageIds) }
  }

  //for normal pages, if current select page is the page, return true
  if (isSamePage) {
    return { isActive: true }
  }

  if (!isSamePage) {
    const selectedPageIndex = productPageIds.indexOf(currentSelectedPageId)
    const selectedPagePosition = selectedPageIndex % 2 === 0 ? "right" : "left"

    //if the selected page is on the right, then try matching the left page
    if (selectedPagePosition === "right") {
      const leftPageId = productPageIds[selectedPageIndex - 1]
      return { isActive: leftPageId === productPageId }
    }
    //if the selected page is on the right, then try matching the right page
    if (selectedPagePosition === "left") {
      const rightPageId = productPageIds[selectedPageIndex + 1] ?? "end"
      return { isActive: rightPageId === productPageId || rightPageId === type }
    }
  }

  return {
    isActive: false,
  }
}
